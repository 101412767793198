import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import "./Purchase.css";

import RadioButtonControl from "../../components/RadioButtonControl";
import RatingButtonControl from "../../components/RatingButtonControl";
import SelectList from "../../components/SelectList";

import { productTypes } from "../../data/productTypes";
import { sizes } from "../../data/sizes";
import { cultivators } from "../../data/cultivators";
import { dispensaries } from "../../data/dispensaries";
import { products } from "../../data/products";

export default function Purchases(props) {
  const [purchase, setPurchase] = useState(null);
  const [content, setContent] = useState(null);

  const [dateOfPurchase, setDateOfPurchase] = useState(null);
  const [productType, setProductType] = useState(null);
  const [sizePurchased, setSizePurchased] = useState(null);
  const [quantityPurchased, setQuantityPurchased] = useState(null);
  const [tierLevel, setTierLevel] = useState("");
  const [strain, setStrain] = useState(null);
  const [strainFlavorOther, setStrainFlavorOther] = useState("");
  const [locationPurchased, setLocationPurchased] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const [brandPurchased, setBrandPurchased] = useState("");
  const [brandOther, setBrandOther] = useState("");
  const [price, setPrice] = useState(undefined);
  const [thcLevel, setThcLevel] = useState(undefined);
  const [cbdLevel, setCbdLevel] = useState(undefined);
  const [rating, setRating] = useState("");
  const [comments, setComments] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadPurchase() {
      return API.get("charges", `/charges/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const purchase = await loadPurchase();
        const { content } = purchase;
        const dt = JSON.parse(content);
        const { dateOfPurchase, productType, sizePurchased, quantityPurchased, strain, locationPurchased, brandPurchased, price, thcLevel, cbdLevel, rating, comments } = dt;
        
        setDateOfPurchase(dateOfPurchase);
        setProductType(productType);
        setSizePurchased(sizePurchased);
        setQuantityPurchased(quantityPurchased);
        setStrain(strain);
        setLocationPurchased(locationPurchased);
        setBrandPurchased(brandPurchased);
        setPrice(price);
        setThcLevel(thcLevel);
        setCbdLevel(cbdLevel);
        setRating(rating);
        setComments(comments);
        setContent(content);
        setPurchase(purchase);
        
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function validateForm() {
    return content.length > 0;
  }

  function savePurchase(){
    var purchaseObject = {
      'dateOfPurchase' : dateOfPurchase,
      'productType': productType,
      'sizePurchased': sizePurchased,
      'quantityPurchased': quantityPurchased,
      'tierLevel': tierLevel,
      'strain': strainFlavorOther.trim().length < 1 ? strain : strainFlavorOther,
      'locationPurchased': locationOther.trim().length < 1 ? locationPurchased : locationOther,
      'brandPurchased': brandOther.trim().length < 1 ? brandPurchased : brandOther,
      'price': price,
      'thcLevel': thcLevel,
      'cbdLevel': cbdLevel,
      'rating': rating,
      'comments': comments
  };


    var purchaseObjectStr = JSON.stringify(purchaseObject);
  
    return API.put("charges", `/charges/${props.match.params.id}`, {
      body: { content: purchaseObjectStr }
    });
  }

  function deletePurchase() {
    return API.del("charges", `/charges/${props.match.params.id}`)
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await savePurchase();
      props.history.push("/");
    } catch(e) {
      alert(e);
      setIsLoading(false);
    }
  }
  
  async function handleDelete(event) {
    event.preventDefault();
  

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);

    try {
      await deletePurchase();
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsDeleting(false);
    }

  }
  
  const productList = [].concat(products).map((item, i) => {
    return { name: item.strain, value: item.tag }
  });

  const dispensariesList = [].concat(dispensaries).map((item, i) => {
    return { name: item.name, value: item.tag}
  });

  const cultivatorsList = [].concat(cultivators).map((item, i) => {
    return { name: item.name, value: item.tag };
  });

  return (
    <div className="purchase-template">
      {purchase && (
        <form onSubmit={handleSubmit}>
          <legend>Date</legend>
          <div className="mui-textfield">
              <input type="date" 
                  id="dateOfPurchase" 
                  value={dateOfPurchase} 
                  onChange={e => setDateOfPurchase(e.target.value)}
              />
              <label htmlFor="dateForPurchase">Date of Purchase</label>
          </div>
          <legend>Product Type</legend>
          <RadioButtonControl radioButtonName="productType" value={productType} items={productTypes} setFunction={setProductType} />
          <legend>Product</legend>
          <div className="mui-select">
              <select id="sizePurchased" value={sizePurchased} onChange={e => setSizePurchased(e.target.value)}>
                  <SelectList items={sizes} />
              </select>
              <label htmlFor="sizePurchased">Size Purchased</label>
          </div>
          <div className="mui-select">
              <select id="quantityPurchased" value={quantityPurchased} onChange={e => setQuantityPurchased(e.target.value)}>
                  <option value="">--Select--</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
              </select>
              <label htmlFor="quantityPurchased">Quantity</label>
          </div>
          <div className="mui-select">
            <select id="tierLevel" value={tierLevel} onChange={e => setTierLevel(e.target.value)} >
              <option value="">--Select--</option>
              <option value="1">1(Below 23%)</option>
              <option value="2">2(23% and above)</option>
            </select>
            <label htmlFor="tier_level">Tier Level</label>
          </div>
          <div className="mui-select">
              <select id="strain" value={strain} onChange={e => setStrain(e.target.value)}>
                  <SelectList items={productList} />
              </select>
              <label htmlFor="strain">Strain/Flavor</label>
          </div>
          <div className="mui-textfield">
              <input type="text" id="strainFlavorOther" value={strainFlavorOther} onChange={e => setStrainFlavorOther(e.target.value)}  />
              <label htmlFor="strainFlavorOther">Strain/Flavor Other</label>
          </div>
          <div className="mui-select">
              <select id="locationPurchased" value={locationPurchased} onChange={e => setLocationPurchased(e.target.value)}>
                  <SelectList items={dispensariesList} />
              </select>
              <label htmlFor="locationPurchased">Location</label>
          </div>
          <div className="mui-textfield">
              <input type="text" id="locationOther" value={locationOther} onChange={e => setLocationOther(e.target.value) } />
              <label htmlFor="locationOther">Location Other</label>
          </div>
          <div className="mui-textfield">
              <input type="number" id="price" placeholder="$" value={price} onChange={e => setPrice(e.target.value)} />
              <label htmlFor="price">Price</label>
          </div>
          <div className="mui-textfield">
              <input type="number" id="thcLevel" placeholder="%" value={thcLevel} onChange={e => setThcLevel(e.target.value)} />
              <label htmlFor="thcLevel">THC</label>
          </div>
          <div className="mui-textfield">
              <input type="number" id="cbdLevel" placeholder="%" value={cbdLevel} onChange={e => setCbdLevel(e.target.value)} />
              <label htmlFor="cbdLevel">CBD</label>
          </div>
          <div className="mui-select">
              <select id="brandPurchased" value={brandPurchased} onChange={e => setBrandPurchased(e.target.value)}>
                  <SelectList items={cultivatorsList} />
              </select>
              <label>Brand</label>
          </div>
          <div className="mui-textfield">
              <input type="text" id="brandOther" value={brandOther} onChange={e => setBrandOther(e.target.value)} />
              <label htmlFor="brandOther">Brand Other</label>
          </div>
          <legend>Ratings</legend>
          <RatingButtonControl radioButtonName="rating" value={rating} setFunction={setRating} 
              items={ 
                  [ 
                      {id: "rating1", value: "1", name: "&#9733;"}, 
                      {id: "rating2", value: "2", name: "&#9733;&#9733;"}, 
                      {id: "rating3", value: "3", name: "&#9733;&#9733;&#9733;"}, 
                      {id: "rating4", value: "4", name: "&#9733;&#9733;&#9733;&#9733;"}, 
                      {id: "rating5", value: "5", name: "&#9733;&#9733;&#9733;&#9733;&#9733;"}, 
                  ] 
          } 
          />
          <div className="mui-textfield">
              <textarea id="comments" name="comments" value={comments} onChange={e => setComments(e.target.value)}>
              </textarea>
              <label>Comments</label>
          </div>
          
          <button
            type="submit"
            className="mui-btn mui-btn--raised mui-btn--primary"
            disabled={!validateForm() || isLoading}
          >
            Save
          </button>
          <button
            className="mui-btn mui-btn--raised mui-btn--danger"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            Delete
          </button>
        </form>
      )}
    </div>
  );
}