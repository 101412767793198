export const edibles = [
    { productType: "edibles", concentrate_type: "gummy", tag: "gummy-blue-raspberry-thc", name: "Blue Raspberry Gummy" },
    { productType: "edibles", concentrate_type: "gummy", tag: "gummy-wana-gummies-mango", name: "Wana Gummies - Mango" },
    { productType: "edibles", concentrate_type: "gummy", tag: "gummy-wild-berry", name: "Wild Berry Gummy" },
    { productType: "edibles", concentrate_type: "gummy", tag: "thc-cbd-watermelon-gummy", name: "THC:CBD Watermelon Gummy" },
    { productType: "edibles", concentrate_type: "gummy", tag: "gummy-wana-gummies-strawberry", name: "Wana Gummies - Strawberry" },
    { productType: "edibles", concentrate_type: "gummy", tag: "gummy-wana-gummies-strawberry-lemonade", name: "Wana Gummies - Strawberry Lemonade" },
    { productType: "edibles", concentrate_type: "gummy", tag: "sriracha-gummy", name: "Sriracha Gummy" },
    { productType: "edibles", concentrate_type: "gummy", tag: "wana-gummies-exotic-yuzu", name: "Wana Gummies - Exotic Yuzu" },
    { productType: "edibles", concentrate_type: "gummy", tag: "peach-mango-jalapeno-gummy", name: "Peach Mango Jalapeno Gummy" },
    { productType: "edibles", concentrate_type: "gummy", tag: "tangerine-passion-fruit-gummy", name: "Tangerine Passion Fruit Gummy" },
    { productType: "edibles", concentrate_type: "gummy", tag: "triple-citrus-gummy", name: "Triple Citrus Gummy" },
    { productType: "edibles", concentrate_type: "gummy", tag: "wana-bLUEBERRY-sour-gummies", name: "Wana BLUEBERRY Sour Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "peach-gummies", name: "Peach Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "kiwi-dragon-fruit-gummies", name: "Kiwi Dragon Fruit Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "tangerine-passionfruit-gummies", name: "Tangerine Passionfruit Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "raspberry-rose-gummies", name: "Raspberry Rose Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "triple-citrus-gummies", name: "Triple Citrus Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "wana-eXOTIC-yUZU-sour-gummies", name: "Wana EXOTIC YUZU Sour Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "wana-mANGO-sour-gummies", name: "Wana MANGO Sour Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "wana-wATERMELON-sour-gummies", name: "Wana WATERMELON Sour Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "mango-gummies", name: "Mango Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "strawberry-gummies", name: "Strawberry Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "tropical-gummies", name: "Tropical Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "root-beer-float-gummies", name: "Root Beer Float Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "gummies-orange-dreamsicle", name: "Gummies Orange Dreamsicle" },
    { productType: "edibles", concentrate_type: "gummy", tag: "gummies-wild-berry-hibiscus", name: "Gummies Wild Berry Hibiscus" },
    { productType: "edibles", concentrate_type: "gummy", tag: "wana-sTRAWBERRY-sour-gummies", name: "Wana STRAWBERRY Sour Gummies" },
    { productType: "edibles", concentrate_type: "gummy", tag: "gummies-strawberry-basil", name: "Gummies Strawberry Basil" },
    { productType: "edibles", concentrate_type: "chocolate", tag: "dark-chocolate-bar", name: "Dark Chocolate Bar" },
    { productType: "edibles", concentrate_type: "chocolate", tag: "dark-chocolate-power-peice", name: "Dark Chocolate power peice" },
    { productType: "edibles", concentrate_type: "chocolate", tag: "buckeyes", name: "Buckeyes" },
    { productType: "edibles", concentrate_type: "chocolate", tag: "milk-chocolate-bar", name: "Milk Chocolate Bar" },
    { productType: "edibles", concentrate_type: "granola", tag: "granola-thc-mixed-berry-squares", name: "Mixed Berry Granola Squares" },
    { productType: "edibles", concentrate_type: "granola", tag: "breakfast-bar", name: "Breakfast Bar" },
    { productType: "edibles", concentrate_type: "tincture", tag: "tincture-thc", name: "Tincture" },
    { productType: "edibles", concentrate_type: "tincture", tag: "cbd-dominant-tincture", name: "CBD dominant Tincture" },
    { productType: "edibles", concentrate_type: "tincture", tag: "thc-cbd-tincture", name: "THC:CBD Tincture" },
    { productType: "edibles", concentrate_type: "capsule", tag: "capsules", name: "Capsules" },
    { productType: "edibles", concentrate_type: "capsule", tag: "indigo-softgel", name: "Indigo Softgel Capsules 19:1 | CBD:THC" },
    { productType: "edibles", concentrate_type: "capsule", tag: "blue-softgel", name: "Blue Softgel Capsules 6:1 | CBD:THC" },
    { productType: "edibles", concentrate_type: "capsule", tag: "green-softgel", name: "Green Softgel Capsules 1:1 | CBD:THC" },
    { productType: "edibles", concentrate_type: "capsule", tag: "softgel-starter-pack", name: "Softgel Starter Pack Indigo-Blue-Green" },
    { productType: "edibles", concentrate_type: "capsule", tag: "yellow-softgel", name: "Yellow Softgel 6:1" },
    { productType: "edibles", concentrate_type: "lozenge", tag: "thc-green-apple-lozenge", name: "Green Apple Lozenge" },
    { productType: "edibles", concentrate_type: "lozenge", tag: "strawberry-hard-candies", name: "Strawberry Hard Candies" },
    { productType: "edibles", concentrate_type: "lozenge", tag: "lemon-drop", name: "Lemon Drop" },
    { productType: "edibles", concentrate_type: "lozenge", tag: "watermelon-drops", name: "Watermelon Drops" },
    { productType: "edibles", concentrate_type: "lozenge", tag: "peppermint-hard-candies", name: "Peppermint Hard Candies" },
    { productType: "edibles", concentrate_type: "caramel", tag: "thc-cbd-soft-caramel-edible", name: "THC:CBD Soft Caramel Edible" },
    { productType: "edibles", concentrate_type: "caramel", tag: "sea-salt-chocolate-covered-caramels", name: "Sea Salt Chocolate Covered Caramels" },
    { productType: "edibles", concentrate_type: "caramel", tag: "soft-caramels", name: "Soft Caramels" },
    { productType: "edibles", concentrate_type: "honey", tag: "honey", name: "Honey" },
]