export const doctors = [
    { office_name: 'Ohio Cannabis Connection', address1: '1495 Morse Rd', address2: '#320', city: 'Columbus', postal: '43229', phone: '(614) 824-2318', email: 'info@ohiocannabisconnection.com', website: 'http://www.ohiocannabisconnection.com',  instagram: 'https://www.instagram.com/ohiocannabisconnection/', twitter: 'https://twitter.com/connection_ohio', facebook: 'https://www.facebook.com/ohiodispensarydoctor', weedmaps: 'https://weedmaps.com/doctors/ohio-cannabis-connection-2'},
    { office_name: 'Ohio Herbal Clinic', address1: '1830 E. Broad St', city: 'Columbus', postal: '43203', phone: '(614) 914-5224', email: 'info@ohioherbalclinic.com', website: 'http://www.OhioHerbalClinic.com',  instagram: 'https://www.instagram.com/ohioherbalclinic/', twitter: 'https://twitter.com/ohioherbal', facebook: 'https://www.facebook.com/ohioherbalclinic', youtube: 'https://www.youtube.com/watch?v=Qp-HOWKmoYY', weedmaps: 'https://weedmaps.com/doctors/ohio-herbal-clinic'},
    { office_name: 'Releaf Health', address1: '850 King Ave.', address2: 'Suite B', city: 'Columbus', postal: '43212', phone: '(614) 437-8382', email: 'info@releafhealthclinic.com', website: 'https://www.releafhealthclinic.com/',  instagram: 'https://www.instagram.com/releafhealthclinic/', facebook: 'https://www.facebook.com/ReLeafHealthClinic', weedmaps: 'https://weedmaps.com/doctors/releaf-health'},
    { office_name: 'Patient Focus', address1: '4700 Reed Rd', city: 'Upper Arlington', postal: '43220', phone: '(216) 438-0523', email: 'intake@pfohio.com', website: 'http://www.pfohio.com',  youtube: 'https://www.youtube.com/watch?v=Zc_oYhmpFdI', weedmaps: 'https://weedmaps.com/doctors/patient-focus-6'},
    { office_name: 'Ohio Green Team', address1: '1580 Fishinger Rd', address2: 'Suite 100', city: 'Upper Arlington', postal: '43221', phone: '(614) 639-0257', email: 'hi@ohiogreenteam.com', website: 'http://Ohiogreenteam.com',  instagram: 'https://www.instagram.com/ohiogreenteam/', twitter: 'https://twitter.com/ohiogreenteam', facebook: 'https://www.facebook.com/OhioGreenTeam/'},
    { office_name: 'Green Harvest Health', address1: '1505 Stonecreek Dr.', address2: 'South Suite 104', city: 'Pickerington', postal: '43147', phone: '(614) 636-5003', email: 'info@greenharvest.health', website: 'http://greenharvest.health',  instagram: 'https://www.instagram.com/greenharvesthealth/', twitter: 'https://twitter.com/GreenHarvHealth', facebook: 'https://www.facebook.com/GREENHARVESTHEALTH'},
    { office_name: 'MediZen Health', address1: '7243 Sawmill Rd', address2: 'Suite# 105', city: 'Dublin', postal: '43017', phone: '614-266-9922', email: 'info@medizenhealth.com', website: 'https://www.medizenhealth.com',  instagram: 'https://www.instagram.com/medizenaesthetics', facebook: 'https://www.facebook.com/medizenclinics'},
    { office_name: 'DocMJ', address1: '1335 Dublin Road', address2: 'Suite 106B', city: 'Columbus', postal: '43215', phone: '(877) 899-3626', email: 'ohio@docmj.com', website: 'https://docmj.com/ohio/',  instagram: 'https://www.instagram.com/docmjohio/', twitter: 'https://twitter.com/DocMJOhio', facebook: 'https://www.facebook.com/docmjohio'},
    { office_name: 'Ohio Marijuana Card', address1: '5050 Blazer Parkway', address2: 'Suite #203', city: 'Dublin', postal: '43017', phone: '(614) 695-6835', email: 'Columbus@OhioMarijuanaCard.com',  website: 'https://www.ohiomarijuanacard.com/columbus-marijuana-doctor',  instagram: 'https://www.instagram.com/ohio_marijuana/', twitter: 'https://twitter.com/marijuana_ohio', facebook: 'https://www.facebook.com/OhioMJ', youtube: 'https://www.youtube.com/watch?v=kz0toWa-pGA'},
    { office_name: 'Medical Marijuana Doctors', address1: '7901 Diley Road', address2: 'Suite 260', city: 'Canal Winchester', postal: '43110', phone: '(614) 920-1000', email: 'ryan.j.rauch@gmail.com', website: 'http://www.medicalmarijuanadoctorsohio.com', facebook: 'https://www.facebook.com/MMDOhio/'},
    { office_name: 'Southern Ohio Green Med', address1: '810 W 2nd Street', address2: 'Unit A', city: 'Waverly', postal: '45690', phone: '(740) 777-6610', email: 'southernohiogreenmed@gmail.com', website: 'http://southernohiogreenmed.com', facebook: 'https://www.facebook.com/southernohiogreenmed'},
    { office_name: 'The Green Remedy', address1: '1505 Bethel Rd', address2: 'Ste 201', city: 'Columbus', postal: '43220', phone: '(614) 838-1138', email: 'info@thegreenremedy.org', website: 'http://www.thegreenremedy.org',  instagram: 'https://www.instagram.com/thegreenremedy420/', twitter: 'https://twitter.com/GreenRemedy420', facebook: 'https://www.facebook.com/thegreenremedy420', weedmaps: 'https://weedmaps.com/doctors/the-green-remedy-2'},
    { office_name: 'Family Healthcare Partners', address1: '145 Sunrise Center Drive', city: 'Zanesville', postal: '43701', phone: '(740) 607-0436', email: 'chiggg@gmail.com'},
    { office_name: 'Serenity Medical Evaluations', email: 'serenitymedicalevaluations@gmail.com', phone: '877 765 0109', website: 'http://serenitymedicalevaluations.com',  twitter: 'https://twitter.com/serenitymedica1'},
    { office_name: 'Medical Cannabis of Northwest Ohio', address1: '815 Defiance St.', city: 'Wapakoneta', postal: '45895', phone: '(419) 738-6269', email: 'mcnwoh@gmail.com', website: 'http://www.mcnwoh.com'},
    { office_name: 'Ohio Green Releaf', address1: '4983 Delhi Pike Ste. 5', city: 'Cincinnati', postal: '45238', phone: '(513) 975-0001', email: 'ohiogreenreleaf@icloud.com', website: 'https://ohiogreenreleaf.com/'},
    { office_name: 'My Marijuana Card', address1: '110 W. Main St', city: 'Fayette', postal: '43521', phone: '(419) 237-7119', email: 'info@mymarijuanacards.com', website: 'https://www.mymarijuanacards.com/',  instagram: 'https://www.instagram.com/ohiomymj_/', youtube: 'https://www.youtube.com/watch?v=lMWvVLIEmaI', facebook: 'https://www.facebook.com/ohiomymj', weedmaps: 'https://weedmaps.com/doctors/athena-certification-center-3'},
    { office_name: 'Restore Compassionate Care', address1: '725 Boardman Canfield Rd', address2: 'Unit L-1', city: 'Boardman', postal: '44512', phone: '330-330-8655', email: 'restorecomcare@gmail.com', website: 'http://www.restorecomcare.com',  facebook: 'https://www.facebook.com/RestoreComCare', weedmaps: 'https://weedmaps.com/doctors/dr-restore-compassionate-care'},
    { office_name: 'Compassionate Cleveland', address1: '23250 Chagrin Blvd.', address2: 'Suite #310', city: 'Beachwood', postal: '44122', phone: '(216) 586-2606', email: 'info@compassionatecleveland.com', website: 'http://www.CompassionateCleveland.com',  instagram: 'https://www.instagram.com/compassionatecleveland/', twitter: 'https://twitter.com/compassion8CLE', facebook: 'https://www.facebook.com/CompassionateCleveland', youtube: 'https://www.youtube.com/watch?v=ynMdZBg-lng'},
    { office_name: 'Lotus Health LLC', address1: '991 Reading Road ', address2: 'Suite 2', city: 'Mason', postal: '45040', phone: '(513) 486-3842', email: 'teaera@lotushealthllc.com', website: 'http://www.lotushealthllc.com', weedmaps: 'https://weedmaps.com/doctors/lotus-health-llc'},
    { office_name: 'Dr. Mark Neumann', phone: '(419) 474-4700', email: 'msndcdo@gmail.com', website: 'http://www.amaohio.com/'},
    { office_name: 'Alternative Medicine Centers of America', address1: '1180 Cleveland Rd West', city: 'Sandusky', postal: '44870', phone: '(877) 603-4420', email: 'sandusky@altmedcenters.com',  website: 'http://altmedcenters.com', facebook: 'https://www.facebook.com/AltMedSandusky/', weedmaps: 'https://weedmaps.com/doctors/alternative-medicine-centers-of-america-2'},
    { office_name: 'AdvoCare Clinic', address1: '157 Wilbur Drive N.E.', city: 'North Canton', postal: '44720', phone: '(833) 423-8622', email: 'info@advocareclinic.com', website: 'https://advocareclinic.com/',  instagram: 'https://www.instagram.com/advocareclinics/', twitter: 'https://twitter.com/advocareclinics', facebook: 'https://www.facebook.com/advocareclinics', weedmaps: 'https://weedmaps.com/doctors/advocare-clinic', youtube: 'https://www.youtube.com/watch?v=CPPocIWnwRo'},
    { office_name: 'Chronic Releaf Certification Center', address1: '5003 N. Summit St.', city: 'Toledo', postal: '43611', phone: '(419) 214-0803', email: 'toldedochronicreleaf@gmail.com',  facebook: 'https://www.facebook.com/ToledoChronicReleaf'},
    { office_name: 'Innovation Wellness', address1: '17 W Main St', city: 'Amelia', postal: '45102', phone: '', email: 'innovationwellnessllc@gmail.com', website: 'http://innovation-wellness.com', weedmaps: 'https://weedmaps.com/doctors/innovation-wellness-2'},
    { office_name: 'Green Compassion Network', address1: '6657 Frank Avenue NW', address2: 'Suite#100', city: 'North Canton', postal: '44720', phone: '(833) 426-4673', email: 'info@gcnhope.com', website: 'http://www.gcnhope.com',  instagram: 'https://www.instagram.com/GCNHope/', twitter: 'https://twitter.com/GCNHope', facebook: 'https://www.facebook.com/GCNHope', weedmaps: 'https://weedmaps.com/doctors/green-compassion-network'},
    { office_name: 'Moral Medical Justification', address1: '34950 Chardon Rd', address2: 'Building 2 Suite 101', city: 'Willoughby Hills', postal: '44094', phone: '(440) 510-8470', email: 'bjsidarido@gmail.com', website: 'http://mmj-ohio.net'},
    { office_name: 'Omni Medical Services', address1: '241 N. Superior St.', city: 'Toledo', postal: '43604', phone: '(888) 470-0008', website: 'http://omnidoctorsoh.com',  email: 'clinics@omnidoctors.com'},
    { office_name: 'Dr. Karin Cseak', address1: '556 Portage Trail', address2: 'Extension W', city: 'Cuyahoga Falls', postal: '44223', phone: '(330) 923-3060', email:'familyholistichealth@yahoo.com',  website: 'http://www.naturalmedicineohio.com/'},
    { office_name: 'Ohio Holistic Healthcare', address1: '570 North Leavitt Road', city: 'Amherst', postal: '44001', phone: '(440) 340-1970', website: 'http://www.ohioholistichealthcare.com',  email: 'ohhc2018@gmail.com'},
    { office_name: 'AccuDoc Medical Marijuana Doctor', address1: '8418 Vine Street', city: 'Cincinnati', postal: '45216', phone: '(513) 407-7203', website: 'http://www.accudocmedicalmarijuanadoctor.com',  email: 'k.caplinger.accudoc@gmail.com'},
    { office_name: 'Alternative Medicinal Solutions / Dr. Charles Barrett', address1: '844 Boulevard St', city: 'Dover', postal: '44622', phone: '(330) 407-7688', email: 'dover420solutions@gmail.com'},
]