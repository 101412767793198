import React from "react";
import "./Menu.css";

import { Link } from "react-router-dom";

export default function Menu(props) {
    return (
        <div className="mui-container side-menu-container">
            <div className="mui--appbar-line-height side-menu-header">
                <div className="mui-row">
                    <div className="mui-col-xs-6">
                        <h2>ohio90.com</h2>
                    </div>
                    <div className="mui-col-xs-6">
                        <button onClick={props.onTitleClick} className="menu-close"><span>&#x2637;</span></button>
                    </div>
                </div>
                
            </div>
            <ul className="side-menu-ul">
                <li>
                    <ul  className="side-menu-sub-ul">
                        <li><Link to="/" onClick={props.onItemSelect}>Home</Link></li>
                    </ul>
                </li>
                <li>
                    <label>Patient</label>
                    <ul className="side-menu-sub-ul">
                        <li><Link to="/patient/dispensaries" onClick={props.onItemSelect}>Dispensaries</Link></li>
                        <li><Link to="/patient/cultivators" onClick={props.onItemSelect}>Cultivators</Link></li>
                        <li><Link to="/patient/mmj-doctors" onClick={props.onItemSelect}>MMJ Doctors</Link></li>
                        <li><Link to="/patient/ohio-mmj-resources" onClick={props.onItemSelect}>Ohio MMJ Resources</Link></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.ohiomedicalmarijuanaregistry.com/logins/sign_in">State MMJ Login</a></li>
                    </ul>
                </li>
                <li>
                    <label>Products</label>
                    <ul className="side-menu-sub-ul">
                        <li><Link to="/products/flower" onClick={props.onItemSelect}>Flower</Link></li>
                        <li><Link to="/products/concentrates" onClick={props.onItemSelect}>Concentrates</Link></li>
                        <li><Link to="/products/edibles" onClick={props.onItemSelect}>Edibles</Link></li>
                        <li><Link to="/products/topicals" onClick={props.onItemSelect}>Topicals</Link></li>
                    </ul>
                </li>
                <li>
                    <label>Company</label>
                    <ul className="side-menu-sub-ul">
                        <li><Link to="/company/about-us" onClick={props.onItemSelect}>About Us</Link></li>
                        <li><Link to="/company/contact-us" onClick={props.onItemSelect}>Contact Us</Link></li>
                        <li><Link to="/company/site-map" onClick={props.onItemSelect}>Site Map</Link></li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}