import React from "react";

export default function ThreeColumns(props) {
    var tcol1 = "", tcol2 = "", tcol3 = "";
    console.log(props);
    const listOfItems = props.listOfItems.map((item, key) => {
        switch(key % 3){
            case 0:
                tcol1 = "";
                tcol2 = "";
                tcol3 = "";
                tcol1 = <div key={"items-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    {props.itemFunction(item)}
                </div>;
            break;
            case 1:
                tcol2 = <div key={"items-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    {props.itemFunction(item)}
                </div>;
            break;
            default:
                tcol3 = <div key={"items-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    {props.itemFunction(item)}
                </div>;
        }

        return ((key % 3 === 2 || key === props.listOfItems.length - 1) &&
            <div key={"mui-row-" + key} className="mui-row">
                {tcol1}
                {tcol2}
                {tcol3}
            </div>);
    });

    return listOfItems;
}