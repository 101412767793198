import React, { useState } from "react";
import "./Purchase.css";
import { API } from "aws-amplify";

import RadioButtonControl from "../../components/RadioButtonControl";
import RatingButtonControl from "../../components/RatingButtonControl";
import SelectList from "../../components/SelectList";

import { productTypes } from "../../data/productTypes";
import { sizes } from "../../data/sizes";
import { cultivators } from "../../data/cultivators";
import { dispensaries } from "../../data/dispensaries";
import { products } from "../../data/products";

export default function NewPurchase(props) {

  const [dateOfPurchase, setDateOfPurchase] = useState("");
  const [productType, setProductType] = useState("flower");
  const [sizePurchased, setSizePurchased] = useState(0);
  const [quantityPurchased, setQuantityPurchased] = useState(0);
  const [tierLevel, setTierLevel] = useState("");
  const [strain, setStrain] = useState("");
  const [strainFlavorOther, setStrainFlavorOther] = useState("");
  const [locationPurchased, setLocationPurchased] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const [brandPurchased, setBrandPurchased] = useState("");
  const [brandOther, setBrandOther] = useState("");
  const [price, setPrice] = useState("");
  const [thcLevel, setThcLevel] = useState("");
  const [cbdLevel, setCbdLevel] = useState("");
  const [rating, setRating] = useState("");
  const [comments, setComments] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return dateOfPurchase.length > 0 &&
        productType !== "" &&
        sizePurchased !== "" &&
        quantityPurchased !== "";

  }

  function createPurchase(purchaseInformation) {
      return API.post("charges", "/charges", {
        body: purchaseInformation
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    var purchaseObject = {
        'dateOfPurchase' : dateOfPurchase,
        'productType': productType,
        'sizePurchased': sizePurchased,
        'quantityPurchased': quantityPurchased,
        'tierLevel': tierLevel,
        'strain': strainFlavorOther.trim().length < 1 ? strain : strainFlavorOther,
        'locationPurchased': locationOther.trim().length < 1 ? locationPurchased : locationOther,
        'brandPurchased': brandOther.trim().length < 1 ? brandPurchased : brandOther,
        'price': price,
        'thcLevel': thcLevel,
        'cbdLevel': cbdLevel,
        'rating': rating,
        'comments': comments
    };


    var purchaseObjectStr = JSON.stringify(purchaseObject);
    
    try {
        await createPurchase({ content: purchaseObjectStr });
        props.history.push("/");
    } catch (e) {
        alert(e);
    }

    setIsLoading(false);
  }

  const productList = [].concat(products).map((item, i) => {
      return { name: item.strain, value: item.tag }
  });

  const dispensariesList = [].concat(dispensaries).map((item, i) => {
    return { name: item.name, value: item.tag}
  });

  const cultivatorsList = [].concat(cultivators).map((item, i) => {
    return { name: item.name, value: item.tag };
  });

  return (
    <div className="purchase-template">
      <form onSubmit={handleSubmit} className="mui-form">
        <legend>Date</legend>
        <div className="mui-textfield">
            <input type="date" 
                id="dateOfPurchase" 
                value={dateOfPurchase} 
                onChange={e => setDateOfPurchase(e.target.value)}
            />
            <label htmlFor="dateForPurchase">Date of Purchase</label>
        </div>
        <legend>Product Type</legend>
        <RadioButtonControl radioButtonName="productType" value={productType} items={productTypes} setFunction={setProductType} />
        <legend>Product</legend>
        <div className="mui-select">
            <select id="sizePurchased" value={sizePurchased} onChange={e => setSizePurchased(e.target.value)}>
                <SelectList items={sizes} />
            </select>
            <label htmlFor="sizePurchased">Size Purchased</label>
        </div>
        <div className="mui-select">
            <select id="quantityPurchased" value={quantityPurchased} onChange={e => setQuantityPurchased(e.target.value)}>
                <option value="">--Select--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
            </select>
            <label htmlFor="quantityPurchased">Quantity</label>
        </div>
        <div className="mui-select">
            <select id="tierLevel" value={tierLevel} onChange={e => setTierLevel(e.target.value)} >
              <option value="">--Select--</option>
              <option value="1">1(Below 23%)</option>
              <option value="2">2(23% and above)</option>
            </select>
            <label htmlFor="tier_level">Tier Level</label>
        </div>
        <div className="mui-select">
            <select id="strain" value={strain} onChange={e => setStrain(e.target.value)}>
                <SelectList items={productList} />
            </select>
            <label htmlFor="strain">Strain/Flavor</label>
        </div>
        <div className="mui-textfield">
            <input type="text" id="strainFlavorOther" value={strainFlavorOther} onChange={e => setStrainFlavorOther(e.target.value)}  />
            <label htmlFor="strainFlavorOther">Strain/Flavor Other</label>
        </div>
        <div className="mui-select">
            <select id="locationPurchased" value={locationPurchased} onChange={e => setLocationPurchased(e.target.value)}>
                <SelectList items={dispensariesList} />
            </select>
            <label htmlFor="locationPurchased">Location</label>
        </div>
        <div className="mui-textfield">
            <input type="text" id="locationOther" value={locationOther} onChange={e => setLocationOther(e.target.value) } />
            <label htmlFor="locationOther">Location Other</label>
        </div>
        <div className="mui-textfield">
            <input type="number" id="price" placeholder="$" value={price} onChange={e => setPrice(e.target.value)} />
            <label htmlFor="price">Price</label>
        </div>
        <div className="mui-textfield">
            <input type="number" id="thcLevel" placeholder="%" value={thcLevel} onChange={e => setThcLevel(e.target.value)} />
            <label htmlFor="thcLevel">THC</label>
        </div>
        <div className="mui-textfield">
            <input type="number" id="cbdLevel" placeholder="%" value={cbdLevel} onChange={e => setCbdLevel(e.target.value)} />
            <label htmlFor="cbdLevel">CBD</label>
        </div>
        <div className="mui-select">
            <select id="brandPurchased" value={brandPurchased} onChange={e => setBrandPurchased(e.target.value)}>
                <SelectList items={cultivatorsList} />
            </select>
            <label>Brand</label>
        </div>
        <div className="mui-textfield">
            <input type="text" id="brandOther" value={brandOther} onChange={e => setBrandOther(e.target.value)} />
            <label htmlFor="brandOther">Brand Other</label>
        </div>
        <legend>Ratings</legend>
        <RatingButtonControl radioButtonName="rating" value={rating} setFunction={setRating} 
            items={ 
                [ 
                    {id: "rating1", value: "1", name: "&#9733;"}, 
                    {id: "rating2", value: "2", name: "&#9733;&#9733;"}, 
                    {id: "rating3", value: "3", name: "&#9733;&#9733;&#9733;"}, 
                    {id: "rating4", value: "4", name: "&#9733;&#9733;&#9733;&#9733;"}, 
                    {id: "rating5", value: "5", name: "&#9733;&#9733;&#9733;&#9733;&#9733;"}, 
                ] 
        } 
        />
        <div className="mui-textfield">
            <textarea id="comments" name="comments" value={comments} onChange={e => setComments(e.target.value)}>
            </textarea>
            <label>Comments</label>
        </div>
        <button
          type="submit"
          disabled={!validateForm() || isLoading}
        >
          Create
        </button>
      </form>
    </div>
  );
}