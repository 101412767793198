import React from "react";
import "./AboutUs.css";

export default function AboutUs() {
    return (
    <div className="mui-container about-us">
        <div className="mui-row">
            <div className="mui-col-md-2 mui-col-xs-12 image-cell">
                <img src="../../../images/ohio90logo-header.png" alt="ohio 90 logo" className="body-logo" />
            </div>
            <div className="mui-col-md-10 mui-col-xs-12">
                <h1>ohio90.com</h1>
                <p>We started this website to help out medical marijuana patient locate resources they need to get started in the process. Provide information on product; where it can be found, what
                    cultivators are growing your ideal strain, and much more.  We also want to keep patient up to date on what is going on in the legal process in the state and the nation surrounding
                    medical marijuana.  We also want to provide information on how to stay healthy, what other states you can medicate in and anything else we can think about writing about other or 
                    providing information about.  We hope you enjoy.
                </p>
            </div>
        </div>
    </div>
    )
} 