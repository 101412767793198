export const dispensaries = [
    { name: "Rise", tag: "rise-lorain", location: "1920 COOPER FOSTER PARK ROAD, LORAIN, OH", county: "Lorain", brandname: "Rise", district: "NORTHEAST‐1", website: "https://risecannabis.com/dispensary-location/ohio/lorain/", phone: "440.363.0237", email: "lorain@risecannabis.com"},
    { name: "NMG OHIO, LLC", tag: "clubhouse-dispensary", location: "709 SUGAR LN., ELYRIA, OH ", county: "Lorain", brandname: "Clubhouse Dispensary", district: "NORTHEAST‐1", website: "https://clubhousedispensary.com/", phone: "440.252.4046"},
    { name: "AFS CULTIVATION, LLC", tag: "afs-cultivation-llc", location: "603 CLEVELAND STREET, ELYRIA, OH", county: "Lorain", brandname: "", district: "NORTHEAST‐1"},
    { name: "GREENLEAF APOTHECARIES, LLC", tag: "the-botanist-cleveland", location: "3865 LAKESIDE AVENUE E, CLEVELAND, OH, 44114", county: "Cuyahoga", brandname: "the Botanist - Cleveland", district: "NORTHEAST‐2", website: "https://www.shopbotanistohio.com/cleveland-location", phone: "(216) 930-6550", email: "cleveland@shopbotanistohio.com"},
    { name: "Rise", tag: "rise-llc-lakewood-madison", location: "11818 MADISON AVE., LAKEWOOD, OH", county: "Cuyahoga", brandname: "Rise - Lakewood", district: "NORTHEAST‐2", website: "https://risecannabis.com/dispensary-location/ohio/lakewood/", phone: "(216)273-0088"},
    { name: "Rise", tag: "rise-llc-cleveland", location: "1222 PROSPECT AVENUE, CLEVELAND, OH, 44115", county: "Cuyahoga", brandname: "Rise - Cleveland", district: "NORTHEAST‐2", website: "https://risecannabis.com/dispensary-location/ohio/cleveland/", phone: "216.243.0165", email: "cleveland@risecannabis.com"},
    { name: "Rise", tag: "rise-lakewood-detroit", location: "18607‐18609 DETROIT AVE., LAKEWOOD, OH", county: "Cuyahoga", brandname: "Rise - Lakewood - Detroit", district: "NORTHEAST‐2", website: "https://risecannabis.com/dispensary-location/ohio/lakewood-detroit/", phone: "(216) 273-0062", email: "LakewoodDetroit@risecannabis.com"},
    { name: "CANNAMED THERAPEUTICS LLC", tag: "terrasana-garfield-heights", location: "10500 ANTENUCCI BOULEVARD, SUITE 200, GARFIELD HEIGHTS, OH", county: "Cuyahoga", brandname: "Terrasana - Garfield Heights", district: "NORTHEAST‐2", website: "https://terrasanacannabisco.com/locations/cleveland-medical-marijuana-dispensary/", phone: "216-232-2527"},
    { name: "127 OH, LLC", tag: "bloom-medicinals-akron", location: "737 EAST NORTH STREET, AKRON, OH", county: "Summit", brandname: "Bloom Medicinals - Akron", district: "NORTHEAST‐3", website: "https://bloomohio.com/akron-medical-marijuana-dispensary", phone: "(567) 213 5101", email: "Info@bloomohio.com"},
    { name: "GREENLEAF APOTHECARIES, LLC", tag: "the-botanist-akron", location: "46 SOUTH SUMMIT STREET, AKRON, OH", county: "Summit", brandname: "the Botantist - Akron", district: "NORTHEAST‐3", website: "https://www.shopbotanistohio.com/akron-location", phone: "330.368.2144", email: "akron@shopbotanistohio.com"},
    { name: "KDJOH, LLC", tag: "herbology-cuyahoga-falls", location: "1220 BUCHHOLZER BLVD, UNITS C/D, CUYAHOGA FALLS, OH", county: "Summit", brandname: "Herbology - Cuyahoga Falls", district: "NORTHEAST‐3", website: "https://www.myherbology.com/ohio/cuyahoga-falls/", phone: "330-476-5019"},
    { name: "GREENLEAF APOTHECARIES, LLC", tag: "greenleaf-apothecaries-llc-canton", location: "3840 GREENTREE SOUTHWEST, Unit 4, CANTON, OH, 44706", county: "Stark", brandname: "the Botantist - Canton", district: "NORTHEAST‐4", website: "https://www.shopbotanistohio.com/canton-location", phone: "330.623.7430", email: "canton@shopbotanistohio.com"},
    { name: "FARMACEUTICALRX LLC", tag: "frx-health", location: "1865 DRESDEN AVENUE, EAST LIVERPOOL, OH", county: "Columbiana", brandname: "FRX Health", district: "NORTHEAST‐4", website: "https://frxohio.com/", phone: "(234) 718-8767", email: "ohio@frxohio.com"},
    { name: "MOTHER KNOW'S BEST, LLC", tag: "mother-knows-best", location: "PARCEL #400887, LOT #5 WITHIN THE STEIN INDUSTRIAL PARK, CANTON, OH", county: "Stark", brandname: "", district: "NORTHEAST‐4"},
    { name: "GREENLEAF APOTHECARIES, LLC", tag: "the-botanist-wickliffe", location: "30133 EUCLID AVENUE, Unit C, WICKLIFFE, OH, 44092", county: "Lake", brandname: "the Botanist - Wickliffe", district: "NORTHEAST‐5", website: "https://www.shopbotanistohio.com/wickliffe-location", phone: "440.671.0420", email: "wickliffe@shopbotanistohio.com"},
    { name: "127 OH, LLC", tag: "bloom-medicinals-painesville", location: "382 BLACKBROOK ROAD, PAINESVILLE TOWNSHIP, OH", county: "Lake", brandname: "Bloom Medicinals - Painesville", district: "NORTHEAST‐5", website: "https://bloomohio.com/painesville-medical-marijuana-dispensary", phone: "440.299.1019", email: "Info@bloomohio.com"},
    { name: "GREEN LEAF MEDICAL OF OHIO II, LLC", tag: "gleaf", location: "2932 YOUNGSTOWN RD, WARREN, OH", county: "Trumbull", brandname: "gLeaf", district: "NORTHEAST‐6", website: "https://www.gleafohio.com/", phone: "330-469-9499"},
    { name: "QUEST WELLNESS OHIO II LLC", tag: "quest-wellness-ohio", location: "4323 MARKET STREET, YOUNGSTOWN, OH", county: "Mahoning", brandname: "Leaf Relief, LLC", district: "NORTHEAST‐6", phone: "(330) 333-3925", email: "info@LeafReliefOhio.com"},
    { name: "127 OH, LLC", tag: "bloom-medicinals", location: "1238 CONANT STREET, MAUMEE, OH", county: "Lucas", brandname: "Bloom Medicinals - Maumee", district: "NORTHWEST‐3", website: "https://bloomohio.com/maumee-medical-marijuana-dispensary", phone: "567-296-9082", email: "Info@bloomohio.com"},
    { name: "Rise", tag: "rise-toledo", location: "3157 W. SYLVANIA AVE, TOLEDO, OH, 43613", county: "Lucas", brandname: "Rise - Toledo", district: "NORTHWEST‐3", website: "https://risecannabis.com/dispensary-location/ohio/toledo/", phone: "419.365.2558", email: "toledo@risecannabis.com"},
    { name: "GLASS CITY ALTERNATIVES, LLC", tag: "glass-city-alternatives", location: "1155 N. MAIN STREET, BOWLING GREEN, OH", county: "Wood", brandname: "Soothe", district: "NORTHWEST‐4", website: "https://www.soothebg.com/", phone: "419-882-8200", email: "info@soothebg.com"},
    { name: "EAGLE DISPENSARIES 2, LLC", tag: "eagle-dispensaries", location: "502 NORTH DIXIE HIGHWAY, WAPAKONETA, OH", county: "Auglaize", brandname: "Eagle Dispensaries", district: "NORTHWEST‐5", website: "https://www.eagledispensaries.com/", phone: "(419) 738-4665"},
    { name: "VERDANT CREATIONS, LLC", tag: "verdant-marion", location: "326 JAMESWAY ROAD, MARION, OH", county: "Marion", brandname: "Verdant Creations - Marion", district: "NORTHWEST‐6", website: "https://www.verdantcreations.com/", phone: "(740) 617-8913"},
    { name: "CANNAMED THERAPEUTICS LLC", tag: "terrasana-sandusky", location: "1800 EAST STATE STREET, FREMONT, OH", county: "Sandusky", brandname: "Terrasana - Sandusky", district: "NORTHWEST‐7", website: "https://terrasanacannabisco.com/locations/fremont-medical-marijuana-dispensary/", phone: "419-463-2703"},
    { name: "OPC RETAIL, LLC", tag: "opc-retial-llc", location: "2018 CLEVELAND ROAD, HURON, OH", county: "Erie", brandname: "", district: "NORTHWEST‐8", phone: "", email: ""},
    { name: "THE FOREST SANDUSKY, LLC", tag: "the-forest", location: "1651 TIFFIN AVENUE, SANDUSKY, OH", county: "Erie", brandname: "The Forest", district: "NORTHWEST‐8", website: "https://www.theforestohio.com/", phone: "(419) 405-6863"},
    { name: "BUCKEYE BOTANICALS LLC", tag: "buckeye-botanicals", location: "3 ACY AVENUE, JACKSON, OH", county: "Jackson", brandname: "Buckeye Botanicals", district: "SOUTHEAST‐1", website: "https://buckeyebotanicals.com/", phone: "(740) 577-9367", email: "Shawl@BuckeyeBotanicals.com"},
    { name: "VERDANT CREATIONS, LLC", tag: "verdant-chillicothe", location: "1641 N. BRIDGE STREET, CHILLICOTHE, OH", county: "Ross", brandname: "Verdant Creations - Chillicothe", district: "SOUTHEAST‐2", website: "https://www.verdantcreations.com/", phone: "(740) 617-8910"},
    { name: "GREENLEAF APOTHECARIES, LLC", tag: "the-botanists-columbus", location: "111 VINE STREET, COLUMBUS, OH", county: "Franklin", brandname: "the Botanist - Columbus", district: "SOUTHEAST‐3", website: "https://www.shopbotanistohio.com/columbus-location", phone: "614.924.2155", email: "columbus@shopbotanistohio.com"},
    { name: "127 OH, LLC", tag: "bloom-medicinals-columbus", location: "1361 GEORGESVILLE RD., COLUMBUS, OH", county: "Franklin", brandname: "Bloom Medicinals - Columbus", district: "SOUTHEAST‐3", website: "https://bloomohio.com/columbus-medical-marijuana-dispensary", phone: "(614) 407 1616", email: "Info@bloomohio.com"},
    { name: "VERDANT CREATIONS, LLC", tag: "verdant-columbus", location: "CASSADY AVENUE (PARCEL # 010‐294448‐00), COLUMBUS, OH", county: "Franklin", brandname: "Verdant Creations - Columbus", district: "SOUTHEAST‐3", website: "https://www.verdantcreations.com/" , phone: "(614) 407-3111"},
    { name: "HARVEST OF OHIO, LLC", tag: "harvest-columbus", location: "2950 N. HIGH ST., COLUMBUS, OH", county: "Franklin", brandname: "Harvest - Columbus", district: "SOUTHEAST‐3", website: "https://www.harvesthoc.com/"},
    { name: "CANNAMED THERAPEUTICS LLC", tag: "terrasana-columbus", location: "656 GRANDVIEW AVE, COLUMBUS, OH", county: "Franklin", brandname: "Terrasana - Columbus", district: "SOUTHEAST‐3", website: "https://terrasanacannabisco.com/locations/columbus-medical-marijuana-dispensary/", phone: "614-434-6929"},
    { name: "VERDANT CREATIONS, LLC", tag: "verdant-creations-newark", location: "551 W. CHURCH STREET, NEWARK, OH", county: "Licking", brandname: "Verdant Creations - Newark", district: "SOUTHEAST‐4", website: "https://www.verdantcreations.com/", phone: "(740) 616-8097"},
    { name: "OHIO GROWN THERAPIES, LLC", tag: "ohio-grown-therapies", location: "1246 N 21ST ST, NEWARK, OH", county: "Licking", brandname: "Ohio Grown Therapies", district: "SOUTHEAST‐4", website: "https://www.ohiogrowntherapies.com/", phone: "(740) 443-3193", email: "info@ohiogrowntherapies.com"},
    { name: "WGB, LLC", tag: "wgb-llc", location: "1150 NORTH 21ST STREET, NEWARK, OH", county: "Licking", brandname: "Herbology", district: "SOUTHEAST‐4", website: "https://www.myherbology.com/ohio/newark/", phone: "740-618-2020"},
    { name: "CANNASCEND ALTERNATIVE LOGAN, LLC", tag: "strawberry-fields-logan", location: "245 WEST MAIN STREET, LOGAN, OH", county: "Hocking", brandname: "Strawberry Fields - Logan", district: "SOUTHEAST‐5", website: "http://www.strawberryfieldsohio.com", phone: "740-300-1821"},
    { name: "BCCO, LLC", tag: "ohio-provisions-bcco", location: "3560 DOLSON CT NW, CARROLL, OH", county: "Fairfield", brandname: "Ohio Provisions By BCCO", district: "SOUTHEAST‐5", website: "https://ohioprovisions.com/", phone: "(740) 993-0444", email: "info@ohioprovisions.com"},
    { name: "HARVEST OF OHIO, LLC", tag: "harvest-athens", location: "711 W. UNION ST., ATHENS, OH", county: "Athens", brandname: "Harvest - Athens", district: "SOUTHEAST‐6", website: "https://www.harvesthoc.com/"},
    { name: "Ohio Cannibis Corporation", tag: "ohio-cannibis-corporation", location: "23024 CR 621, SUITE 1, Coshocton, OH", county: "Coshocton" , brandname: "Ohio Cannibis Corporation", district: "SOUTHEAST‐7", website: "http://www.occdispensary.com/", phone: "740-295-9182"},
    { name: "CANNASCEND ALTERNATIVE, LLC", tag: "strawberry-fields-marietta", location: "414 GREENE STREET, MARIETTA, OH", county: "Washington", brandname: "Strawberry Fields - Marietta", district: "SOUTHEAST‐8", website: "http://www.strawberryfieldsohio.com", phone: "740-238-7603"},
    { name: "CRESCO LABS OHIO LLC", tag: "cresco-labs-ohio-wintersville", location: "180 MAIN STREET, WINTERSVILLE, OH", county: "Jefferson", brandname: "Cresco Labs", district: "SOUTHEAST‐9", website: "https://www.cydispensary.com/locations/#wintersville", phone: "(740) 617-8649"},
    { name: "OHIO VALLEY NATURAL RELIEF LLC", tag: "ohio-valley-natural-relief", location: "840 CANTON ROAD, WINTERSVILLE, OH", county: "Jefferson", brandname: "OVNR(Ohio Valley Natural Relief)", district: "SOUTHEAST‐9", website: "https://www.ohiovalleynaturalreliefllc.com/", phone: "740-792-4267"},
    { name: "VERDANT CREATIONS, LLC", tag: "verdant-creations-llc-cincinnati", location: "5149 KENNEDY AVENUE, CINCINNATI, OH", county: "Hamilton", brandname: "", district: "SOUTHWEST‐1", website: "https://www.verdantcreations.com/", phone: "(513) 964-1106"},
    { name: "GREEN RX, LLC", tag: "have-a-heart-cincy", location: "8420 VINE STREET, CINCINNATI, OH", county: "Hamilton", brandname: "Have A Heart Cincy", district: "SOUTHWEST‐1", website: "https://www.haveaheartcincy.com/", phone: "513-440-6340"},
    { name: "PHARMACANN OHIO LLC", tag: "pharmacann-ohio-columbia", location: "5445 RIDGE ROAD, COLUMBIA, OH", county: "Hamilton", brandname: "Verilife", district: "SOUTHWEST‐1", website: "https://www.verilife.com/oh", phone: "513-599-1550"},
    { name: "127 OH, LLC", tag: "bloom-medicinals-seven-mile", location: "VILLAGE OF SEVEN MILE, OH", county: "Butler", brandname: "Bloom Medicinals - Seven Mile", district: "SOUTHWEST‐2", website: "https://bloomohio.com/seven-mile-medical-marijuana-dispensary", phone: "(513) 447 6459", email: "Info@bloomohio.com"},
    { name: "CANNASCEND ALTERNATIVE, LLC", tag: "strawberry-fields-monroe", location: "300 NORTH MAIN STREET, MONROE, OH", county: "Butler", brandname: "Strawberry Fields - Monroe", district: "SOUTHWEST‐2", website: "http://www.strawberryfieldsohio.com", phone: "513-506-1857"},
    { name: "PURE OHIO WELLNESS LLC", tag: "pure-ohio-wellness-dayton", location: "1875 NEEDMORE ROAD, DAYTON, OH", county: "Montgomery", brandname: "Pure Ohio Wellness - Sprinfield", district: "SOUTHWEST‐4", website: "http://pureohiowellness.com/rd/index.php/locations/dayton-ohio", phone: "(937) 519-1420"},
    { name: "CANNASCEND ALTERNATIVE, LLC", tag: "strawberry-fields-dayton", location: "333 WAYNE AVENUE, DAYTON, OH", county: "Montgomery", brandname: "Strawberry Fields - Dayton", district: "SOUTHWEST‐4", website: "http://www.strawberryfieldsohio.com", phone: "937-233-0642"},
    { name: "SCHOTTENSTEIN APHRIA III LLC", tag: "mad-river-remedies", location: "5604 AIRWAY ROAD, RIVERSIDE, OH", county: "Montgomery", brandname: "Mad River Remedies", district: "SOUTHWEST‐4", website: "https://madriverremedies.com/", phone: "(937) 684-4621"},
    { name: "THERAPEUTIC HEALING CARE II, LLC", tag: "about-wellness-ohio", location: "1525 Genntown Dr, Lebanon, OH 45036", county: "Warren", brandname: "About Wellness Ohio", district: "SOUTHWEST‐5", website: "https://www.aboutwellnessohio.com/", phone: "513-970-2080", email: "CARE@ABOUTWELLNESSOHIO.COM"},
    { name: "DEBBIE'S DISPENSARY OHIO 4, LLC", tag: "debbies-dispensary", location: "1088 N HIGH ST, HILLSBORO, OH", county: "Highland", brandname: "Debbie's Dispnsary", district: "SOUTHWEST‐6", website: "https://www.debbiesohio.com/", phone: "(937) 402-4016"},
    { name: "HARVEST OF OHIO, LLC", tag: "harvest-beavercreek", location: "4370 TONAWANDA TRAIL, BEAVERCREEK, OH", county: "Greene", brandname: "Harvest - Beavercreek", district: "SOUTHWEST‐7", website: "https://www.harvesthoc.com/"},
    { name: "PURE OHIO WELLNESS LLC", tag: "pure-ohio-wellness-springfield", location: "1711 WEST MAIN ST., SPRINGFIELD, OH", county: "Clark", brandname: "Pure Ohio Wellness - Springfield", district: "SOUTHWEST‐8", website: "http://pureohiowellness.com/rd/index.php/locations/springfield-ohio", phone: "(937) 471-1442"},
    { name: "CANNAMED THERAPEUTICS LLC", tag: "terrasana-springfield", location: "0 RAYDO CIRCLE, SPRINGFIELD, OH", county: "Clark", brandname: "Terrasana - Springfield", district: "SOUTHWEST‐8", website: "https://terrasanacannabisco.com/locations/springfield-medical-marijuana-dispensary/", phone: "937-230-6505"},
];