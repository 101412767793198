import React from "react";
import "./Edibles.css";
import { edibles } from "../../../data/edibles";

export default function Edibles() {

    var createItemContainer = (item) => {
        return (
            <h3>{item.name}</h3>
        );
    };

    var createColumns = (listOfItems, itemDisplayFunction) => {
        var tcol1 = "", tcol2 = "", tcol3 = "";
        const returnList = listOfItems.map((item, key) => {
            switch(key % 3){
                case 0:
                    tcol1 = "";
                    tcol2 = "";
                    tcol3 = "";
                    tcol1 = <div key={"col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                        {itemDisplayFunction(item)}
                    </div>;
                break;
                case 1:
                    tcol2 = <div key={"col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                         {itemDisplayFunction(item)}
                    </div>;
                break;
                default:
                    tcol3 = <div key={"col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                         {itemDisplayFunction(item)}
                    </div>;
            }
            return ((key % 3 === 2 || key === listOfItems.length - 1) &&
                <div key={"mui-row" + key} className="mui-row">
                    {tcol1}
                    {tcol2}
                    {tcol3}
                </div>);
        });

        return returnList;
    };



    

    var gummy_edibles = edibles.filter(item => item.concentrate_type === "gummy").sort((a, b)=> { return a.name < b.name ? -1 : 1 });
    var chocolate_edibles = edibles.filter(item => item.concentrate_type === "chocolate").sort((a, b)=> { return a.name < b.name ? -1 : 1 });
    var granola_edibles = edibles.filter(item => item.concentrate_type === "granola").sort((a, b)=> { return a.name < b.name ? -1 : 1 });
    var tincture_edibles = edibles.filter(item => item.concentrate_type === "tincture").sort((a, b)=> { return a.name < b.name ? -1 : 1 });
    var capsule_edibles = edibles.filter(item => item.concentrate_type === "capsule").sort((a, b)=> { return a.name < b.name ? -1 : 1 });
    var lozenge_edibles = edibles.filter(item => item.concentrate_type === "lozenge").sort((a, b)=> { return a.name < b.name ? -1 : 1 });
    var caramel_edibles = edibles.filter(item => item.concentrate_type === "caramel").sort((a, b)=> { return a.name < b.name ? -1 : 1 });
    var honey_edibles = edibles.filter(item => item.concentrate_type === "honey").sort((a, b)=> { return a.name < b.name ? -1 : 1 });

    const listOfGummies = createColumns(gummy_edibles, createItemContainer);
    const listOfChocolates = createColumns(chocolate_edibles, createItemContainer);
    const listofGranola = createColumns(granola_edibles, createItemContainer);
    const listOfTincture = createColumns(tincture_edibles, createItemContainer);
    const listOfCapsules = createColumns(capsule_edibles, createItemContainer);
    const listOfLozenge = createColumns(lozenge_edibles, createItemContainer);
    const listOfCaramel = createColumns(caramel_edibles, createItemContainer);
    const listOfHoney = createColumns(honey_edibles, createItemContainer);
    
    return (
        <div className="mui-container edibles">
            <h2>Edibles</h2>
            <div className="mui-row section-area">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <h3>Available Gummies</h3>
                    <div className="div-img-frame mobile-only">
                        <img src="/images/content/Edibles/gummy.png" alt="Gummy" />
                    </div>
                    <p>There is a large variety of gummies avaiable on the market.</p>
                    <div className="mui-container listing">
                        {listOfGummies.length > 0 && 
                            <div className="mui-container-fluid edibles">
                                {listOfGummies}  
                            </div>                
                        }

                    </div>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 desktop-only">
                    <div className="div-img-frame">
                        <img src="/images/content/Edibles/gummy.png" alt="Gummy" />
                    </div>
                </div>
            </div>

            <div className="mui-row section-area">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <h3>Chocolates</h3>
                    <div className="div-img-frame mobile-only">
                        <img src="/images/content/Edibles/chocolate.png" alt="Chocolate" />
                    </div>
                    <p>From chocolate bars to bon bons to buckeyes, Ohio has a large variety of medical marijuana infused chocolate.</p>
                    <div className="mui-container listing">
                        {listOfChocolates.length > 0 && 
                            <div className="mui-container-fluid edibles">
                                {listOfChocolates}  
                            </div>                
                        }
                    </div>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 desktop-only">
                    <div className="div-img-frame">
                        <img src="/images/content/Edibles/chocolate.png" alt="Chocolate" />
                    </div>
                </div>
            </div>
            <div className="mui-row section-area">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <h3>Granola/Breakfast Bars</h3>
                    <div className="div-img-frame mobile-only">
                        <img src="/images/content/Edibles/granola.png" alt="Granola" />
                    </div>
                    <p>There is a large variety of granola snacks and breakfast bars available from a variety of vendors</p>
                    <div className="mui-container listing">
                        {listofGranola.length > 0 && 
                            <div className="mui-container-fluid edibles">
                                {listofGranola}  
                            </div>                
                        }
                    </div>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 desktop-only">
                    <div className="div-img-frame">
                        <img src="/images/content/Edibles/granola.png" alt="Granola" />
                    </div>
                </div>
            </div>
            <div className="mui-row section-area">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <h3>Tincture</h3>
                    <div className="div-img-frame mobile-only">
                        <img src="/images/content/Edibles/tincture.png" alt="Tincture" />
                    </div>
                    <p>If you are used to using tincture for your medicinal needs, Ohio has a variety of flavors and strengths.</p>
                    <div className="mui-container listing">
                        {listOfTincture.length > 0 && 
                            <div className="mui-container-fluid edibles">
                                {listOfTincture}  
                            </div>                
                        }
                    </div>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 desktop-only">
                    <div className="div-img-frame">
                        <img src="/images/content/Edibles/tincture.png" alt="Tincture" />
                    </div>
                </div>
            </div>
            <div className="mui-row section-area">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <h3>Capsules</h3>                    
                    <div className="div-img-frame mobile-only">
                        <img src="/images/content/Edibles/capsule.png" alt="Capsule" />
                    </div>
                    <p>If you like to ingest tablets or capsules you can get a large variety of CBD and THC types.</p>
                    <div className="mui-container listing">
                        {listOfCapsules.length > 0 && 
                            <div className="mui-container-fluid edibles">
                                {listOfCapsules}  
                            </div>                
                        }
                    </div>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 desktop-only">
                    <div className="div-img-frame">
                        <img src="/images/content/Edibles/capsule.png" alt="Capsule" />
                    </div>
                </div>
            </div>
            <div className="mui-row section-area">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <h3>Hard Candies/Lozenge</h3>
                    <div className="div-img-frame mobile-only">
                        <img src="/images/content/Edibles/hard-candy.png" alt="Hard Candies and Lozenge" />
                    </div>
                    <p>Many different flavors to choose from.</p>
                    <div className="mui-container listing">
                        {listOfLozenge.length > 0 && 
                            <div className="mui-container-fluid edibles">
                                {listOfLozenge}  
                            </div>                
                        }
                    </div>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 desktop-only">
                    <div className="div-img-frame">
                        <img src="/images/content/Edibles/hard-candy.png" alt="Hard Candies and Lozenge" />
                    </div>
                </div>
            </div>
            <div className="mui-row section-area">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <h3>Caramel</h3>
                    <div className="div-img-frame mobile-only">
                        <img src="/images/content/Edibles/caramel.png" alt="Caramel" />
                    </div>
                    <p>Caramels, there are a variety of different types.</p>
                    <div className="mui-container listing">
                        {listOfCaramel.length > 0 && 
                            <div className="mui-container-fluid edibles">
                                {listOfCaramel}  
                            </div>                
                        }
                    </div>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 desktop-only">
                    <div className="div-img-frame">
                        <img src="/images/content/Edibles/caramel.png" alt="Caramel" />
                    </div>
                </div>
            </div>
            <div className="mui-row section-area">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <h3>Cooking Products</h3>                    
                    <div className="div-img-frame mobile-only">
                        <img src="/images/content/Edibles/honey.png" alt="Honey" />
                    </div>
                    <p>There is a variety of items you can use to get your medicine while you eat every day things.  Here are a few items below.</p>
                    <div className="mui-container listing">
                        {listOfHoney.length > 0 && 
                            <div className="mui-container-fluid edibles">
                                {listOfHoney}  
                            </div>                
                        }
                    </div>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 desktop-only">
                    <div className="div-img-frame">
                        <img src="/images/content/Edibles/honey.png" alt="Honey" />
                    </div>
                </div>
            </div>
        </div>
    )
}