export const ohio_flower_chart = [
    { size: 0.1, grams: 2.83, tier1_cost: 2, tier2_cost: 2 },
    { size: 0.2, grams: 5.66, tier1_cost: 3, tier2_cost: 4 },
    { size: 0.3, grams: 8.49, tier1_cost: 4, tier2_cost: 6 },
    { size: 0.4, grams: 11.32, tier1_cost: 5, tier2_cost: 7 },
    { size: 0.5, grams: 14.15, tier1_cost: 6, tier2_cost: 9 },
    { size: 0.6, grams: 16.98, tier1_cost: 7, tier2_cost: 11 },
    { size: 0.7, grams: 19.81, tier1_cost: 8, tier2_cost: 12 },
    { size: 0.8, grams: 22.64, tier1_cost: 9, tier2_cost: 14 },
    { size: 0.9, grams: 25.47, tier1_cost: 11, tier2_cost: 16 },
    { size: 1.0, grams: 28.30, tier1_cost: 12, tier2_cost: 17 },
    { size: 1.1, grams: 31.13, tier1_cost: 13, tier2_cost: 19 },
    { size: 1.2, grams: 33.96, tier1_cost: 14, tier2_cost: 21 },
    { size: 1.3, grams: 36.79, tier1_cost: 15, tier2_cost: 23 },
    { size: 1.4, grams: 39.62, tier1_cost: 16, tier2_cost: 24 },
    { size: 1.5, grams: 42.45, tier1_cost: 17, tier2_cost: 26 },
    { size: 1.6, grams: 45.28, tier1_cost: 18, tier2_cost: 28 },
    { size: 1.7, grams: 48.11, tier1_cost: 20, tier2_cost: 29 },
    { size: 1.8, grams: 50.94, tier1_cost: 21, tier2_cost: 31 },
    { size: 1.9, grams: 53.77, tier1_cost: 22, tier2_cost: 33 },
    { size: 2.0, grams: 56.60, tier1_cost: 23, tier2_cost: 34 },
    { size: 2.1, grams: 59.43, tier1_cost: 24, tier2_cost: 36 },
    { size: 2.2, grams: 62.26, tier1_cost: 25, tier2_cost: 38 },
    { size: 2.3, grams: 65.09, tier1_cost: 26, tier2_cost: 40 },
    { size: 2.4, grams: 67.92, tier1_cost: 27, tier2_cost: 41 },
    { size: 2.5, grams: 70.75, tier1_cost: 29, tier2_cost: 43 },
    { size: 2.6, grams: 73.58, tier1_cost: 30, tier2_cost: 45 },
    { size: 2.7, grams: 76.41, tier1_cost: 31, tier2_cost: 46 },
    { size: 2.8, grams: 79.24, tier1_cost: 32, tier2_cost: 48 },
    { size: 2.9, grams: 82.07, tier1_cost: 33, tier2_cost: 50 },
    { size: 3.0, grams: 84.90, tier1_cost: 34, tier2_cost: 51 },
    { size: 3.1, grams: 87.73, tier1_cost: 35, tier2_cost: 53 },
    { size: 3.2, grams: 90.56, tier1_cost: 36, tier2_cost: 55 },
    { size: 3.3, grams: 93.39, tier1_cost: 38, tier2_cost: 57 },
    { size: 3.4, grams: 96.22, tier1_cost: 39, tier2_cost: 58 },
    { size: 3.5, grams: 99.05, tier1_cost: 40, tier2_cost: 60 },
    { size: 3.6, grams: 101.88, tier1_cost: 41, tier2_cost: 62 },
    { size: 3.7, grams: 104.71, tier1_cost: 42, tier2_cost: 63 },
    { size: 3.8, grams: 107.54, tier1_cost: 43, tier2_cost: 65 },
    { size: 3.9, grams: 110.37, tier1_cost: 44, tier2_cost: 67 },
    { size: 4.0, grams: 113.20, tier1_cost: 45, tier2_cost: 68 },
    { size: 4.1, grams: 116.03, tier1_cost: 47, tier2_cost: 70 },
    { size: 4.2, grams: 118.86, tier1_cost: 48, tier2_cost: 72 },
    { size: 4.3, grams: 121.69, tier1_cost: 49, tier2_cost: 74 },
    { size: 4.4, grams: 124.52, tier1_cost: 50, tier2_cost: 75 },
    { size: 4.5, grams: 127.35, tier1_cost: 51, tier2_cost: 77 },
    { size: 4.6, grams: 130.18, tier1_cost: 52, tier2_cost: 79 },
    { size: 4.7, grams: 133.01, tier1_cost: 53, tier2_cost: 80 },
    { size: 4.8, grams: 135.84, tier1_cost: 54, tier2_cost: 82 },
    { size: 4.9, grams: 138.67, tier1_cost: 56, tier2_cost: 84 },
    { size: 5.0, grams: 141.50, tier1_cost: 57, tier2_cost: 85 },
    { size: 5.1, grams: 144.33, tier1_cost: 58, tier2_cost: 87 },
    { size: 5.2, grams: 147.16, tier1_cost: 59, tier2_cost: 89 },
    { size: 5.3, grams: 149.99, tier1_cost: 60, tier2_cost: 90 },
    { size: 5.4, grams: 152.82, tier1_cost: 61, tier2_cost: -1 },
    { size: 5.5, grams: 155.65, tier1_cost: 62, tier2_cost: -1 },
    { size: 5.6, grams: 158.48, tier1_cost: 64, tier2_cost: -1 },
    { size: 5.7, grams: 161.31, tier1_cost: 65, tier2_cost: -1 },
    { size: 5.8, grams: 164.14, tier1_cost: 66, tier2_cost: -1 },
    { size: 5.9, grams: 166.97, tier1_cost: 67, tier2_cost: -1 },
    { size: 6.0, grams: 169.80, tier1_cost: 68, tier2_cost: -1 },
    { size: 6.1, grams: 172.63, tier1_cost: 69, tier2_cost: -1 },
    { size: 6.2, grams: 175.46, tier1_cost: 70, tier2_cost: -1 },
    { size: 6.3, grams: 178.29, tier1_cost: 71, tier2_cost: -1 },
    { size: 6.4, grams: 181.12, tier1_cost: 72, tier2_cost: -1 },
    { size: 6.5, grams: 183.95, tier1_cost: 74, tier2_cost: -1 },
    { size: 6.6, grams: 186.78, tier1_cost: 75, tier2_cost: -1 },
    { size: 6.7, grams: 189.61, tier1_cost: 76, tier2_cost: -1 },
    { size: 6.8, grams: 192.44, tier1_cost: 77, tier2_cost: -1 },
    { size: 6.9, grams: 195.27, tier1_cost: 78, tier2_cost: -1 },
    { size: 7.0, grams: 198.10, tier1_cost: 79, tier2_cost: -1 },
    { size: 7.1, grams: 200.93, tier1_cost: 80, tier2_cost: -1 },
    { size: 7.2, grams: 203.76, tier1_cost: 82, tier2_cost: -1 },
    { size: 7.3, grams: 206.59, tier1_cost: 83, tier2_cost: -1 },
    { size: 7.4, grams: 209.42, tier1_cost: 84, tier2_cost: -1 },
    { size: 7.5, grams: 212.25, tier1_cost: 85, tier2_cost: -1 },
    { size: 7.6, grams: 215.08, tier1_cost: 86, tier2_cost: -1 },
    { size: 7.7, grams: 217.91, tier1_cost: 87, tier2_cost: -1 },
    { size: 7.8, grams: 220.74, tier1_cost: 88, tier2_cost: -1 },
    { size: 7.9, grams: 223.57, tier1_cost: 89, tier2_cost: -1 },
    { size: 8.0, grams: 226.40, tier1_cost: 90, tier2_cost: -1 },
];