import React from "react";
import { Route, Redirect } from "react-router-dom";
function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function UnauthenticatedRoute({ component: C, appProps, ...rest }) {
    const redirect = querystring("redirect");
    var is21 = localStorage.getItem("is21") === null ? appProps.is21 : localStorage.getItem("is21").toString() === "true" ? true : false;
    return (
      <Route
        {...rest}
        render={props =>
          is21
            ? <C {...props} {...appProps} />
            : <Redirect
                to={redirect === "" || redirect === null ? "/over-twenty-one" : redirect}
              />}
      />
    );
  }