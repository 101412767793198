export const products = [
    { productType: "flower", strain: "Northern Ligths", tag: "northern-lights", type: "Indica" },
    { productType: "flower", strain: "Meigs County Honey", tag: "meigs-county-honey", type: "Hybrid" },
    { productType: "flower", strain: "Meigs County Gold", tag: "meigs-county-gold", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County White Tahoe", tag: "meigs-county-white-tahoe", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County Wave", tag: "meigs-county-wave", type: "Sativa"}, 
    { productType: "flower", strain: "Meigs County Express", tag: "meigs-county-express", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County Purple", tag: "meigs-county-purple", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County MEOW", tag: "meigs-county-meow", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County Cake", tag: "meigs-county-cake", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County Chocolope", tag: "meigs-county-chocolope", type: "Sativa"}, 
    { productType: "flower", strain: "Meigs County Dirt Nap", tag: "meigs-county-dirt-nap", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County Ksmorz", tag: "meigs-county-ksmorz", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County Matherly's Private Reserve #1", tag: "meigs-county-matherly-s-private-reserve", type: "Sativa"}, 
    { productType: "flower", strain: "Meigs County Samoas", tag: "meigs-county-samoas", type: "Hybrid"}, 
    { productType: "flower", strain: "Sundae Driver", tag: "sundae-driver", type: "Indica"}, 
    { productType: "flower", strain: "Whip It", tag: "whip-it", type: "Hybrid"}, 
    { productType: "flower", strain: "Ice Cream Cake", tag: "ice-cream-cake", type: "Indica"}, 
    { productType: "flower", strain: "ORyan OG", tag: "oryan-og", type: "Indica"}, 
    { productType: "flower", strain: "Josh D OG", tag: "josh-d-og", type: "Indica"}, 
    { productType: "flower", strain: "Orange 43", tag: "orange-43", type: "Sativa"}, 
    { productType: "flower", strain: "Sherbhead", tag: "sherbhead", type: "Indica"}, 
    { productType: "flower", strain: "Dolce", tag: "dolce", type: "Indica"}, 
    { productType: "flower", strain: "Dosiface", tag: "dosiface", type: "Hybrid"}, 
    { productType: "flower", strain: "Chocolope", tag: "chocolope", type: "Sativa"}, 
    { productType: "flower", strain: "Garlic Cookies", tag: "garlic-cookies", type: "Indica"}, 
    { productType: "flower", strain: "Island Girl", tag: "island-girl", type: "Sativa"}, 
    { productType: "flower", strain: "92 Cookies", tag: "92-cookies", type: "Sativa"}, 
    { productType: "flower", strain: "Phog Walker", tag: "phog-walker", type: "Indica"}, 
    { productType: "flower", strain: "Lemon Dosidos", tag: "lemon-dosidos", type: "Indica"}, 
    { productType: "flower", strain: "Dosidos 22", tag: "dosidos-22", type: "Indica"}, 
    { productType: "flower", strain: "Secret Formula", tag: "secret-formula", type: "Hybrid"}, 
    { productType: "flower", strain: "One Drop", tag: "one-drop", type: "Hybrid"}, 
    { productType: "flower", strain: "Yuzu", tag: "yuzu", type: "Hybrid"}, 
    { productType: "flower", strain: "Trilogy", tag: "Wellspring Fields", type: "Indica"}, 
    { productType: "flower", strain: "Miami Heat", tag: "miami-heat", type: "Sativa"}, 
    { productType: "flower", strain: "Grapefruit Sour Dream", tag: "grapefruit-sour-dream", type: "Sativa"}, 
    { productType: "flower", strain: "Sunset Sherbert", tag: "sunset-sherbert", type: "Indica"}, 
    { productType: "flower", strain: "Shark Shock", tag: "shark-shock", type: "Hybrid CBD"}, 
    { productType: "flower", strain: "Layer Cake", tag: "layer-cake", type: "Indica"}, 
    { productType: "flower", strain: "Super Lemon Haze", tag: "super-lemon-haze", type: "Sativa"}, 
    { productType: "flower", strain: "Limestone 57", tag: "limestone-57", type: "Hybrid"}, 
    { productType: "flower", strain: "Blackjack", tag: "blackjack", type: "Sativa"}, 
    { productType: "flower", strain: "Captains Cake", tag: "captains-cake", type: "Hybrid"}, 
    { productType: "flower", strain: "Crushed Berries", tag: "crushed-berries", type: "Hybrid"}, 
    { productType: "flower", strain: "Thin Mint Cookies", tag: "thin-mint-cookies", type: "Hybrid"}, 
    { productType: "flower", strain: "Triple Chocolate Chip", tag: "triple-chocolate-chip", type: "Indica"}, 
    { productType: "flower", strain: "Strawberry Cough", tag: "strawberry-cough", type: "Sativa"}, 
    { productType: "flower", strain: "True OG", tag: "true-og", type: "Indica"}, 
    { productType: "flower", strain: "Paradise Circus", tag: "paradise-circus", type: "Sativa"}, 
    { productType: "flower", strain: "Sour Kush", tag: "sour-kush", type: "Hybrid"}, 
    { productType: "flower", strain: "Chem OG", tag: "chem-og", type: "Hybrid"}, 
    { productType: "flower", strain: "Tribal Delight", tag: "tribal-delight", type: "Indica"}, 
    { productType: "flower", strain: "GG #4", tag: "gg4", type: "Indica"}, 
    { productType: "flower", strain: "Grape Lime Ricky", tag: "grape-lime-ricky", type: "Indica"}, 
    { productType: "flower", strain: "Bio J", tag: "bio-j", type: "Indica"}, 
    { productType: "flower", strain: "Jackerella", tag: "jackerella", type: "Sativa"}, 
    { productType: "flower", strain: "Papaya Punch", tag: "papaya-punch", type: "Indica"}, 
    { productType: "flower", strain: "Blue Blast", tag: "blue-blast", type: "Hybrid"}, 
    { productType: "flower", strain: "Chiesel", tag: "chiesel", type: "Hybrid"}, 
    { productType: "flower", strain: "Cannatonic", tag: "cannatonic", type: "Hybrid"}, 
    { productType: "flower", strain: "Koko", tag: "koko", type: "Hybrid"}, 
    { productType: "flower", strain: "NYC Diesel", tag: "nyc-diesel", type: "Hybrid"}, 
    { productType: "flower", strain: "CTEN", tag: "cten", type: "Sativa"}, 
    { productType: "flower", strain: "OG Kush", tag: "og-kush", type: "Indica"}, 
    { productType: "flower", strain: "Tropicana Pie", tag: "tropicana-pie", type: "Hybrid"}, 
    { productType: "flower", strain: "Durban", tag: "durban", type: "Sativa"}, 
    { productType: "flower", strain: "Animal Fire OG", tag: "animal-fire-og", type: "Hybrid"}, 
    { productType: "flower", strain: "Flavor Country", tag: "flavor-country", type: "Indica"}, 
    { productType: "flower", strain: "Jet - A", tag: "jet-a", type: "Indica"}, 
    { productType: "flower", strain: "Cookies & Cream", tag: "cookies-cream", type: "Hybrid"}, 
    { productType: "flower", strain: "Gelato", tag: "gelato", type: "Hybrid"}, 
    { productType: "flower", strain: "Headband 216", tag: "headband-216", type: "Sativa"}, 
    { productType: "flower", strain: "Cali Dragon", tag: "cali-dragon", type: "Hybrid"}, 
    { productType: "flower", strain: "Mimosa", tag: "mimosa", type: "Hybrid"}, 
    { productType: "flower", strain: "Pheno 51", tag: "pheno-51", type: "Hybrid"}, 
    { productType: "flower", strain: "Screaming Eagle", tag: "screaming-eagle", type: "Hybrid"}, 
    { productType: "flower", strain: "Sour Papaya", tag: "sour-papaya", type: "Hybrid"}, 
    { productType: "flower", strain: "Cherry Star", tag: "cherry-star", type: "Indica"}, 
    { productType: "flower", strain: "Eclipse", tag: "eclipse", type: "Sativa"}, 
    { productType: "flower", strain: "OG 18", tag: "og-18", type: "Indica"}, 
    { productType: "flower", strain: "Oro Blanco", tag: "oro-blanco", type: "Hybrid"}, 
    { productType: "flower", strain: "4 Kings", tag: "4-kings", type: "Indica"}, 
    { productType: "flower", strain: "Burkle", tag: "burkle", type: "Indica"}, 
    { productType: "flower", strain: "Mumbles", tag: "mumbles", type: "Sativa"}, 
    { productType: "flower", strain: "Blue Boi", tag: "blue-boi", type: "Hybrid"}, 
    { productType: "flower", strain: "Cannaradosi", tag: "cannaradosi", type: "Indica"}, 
    { productType: "flower", strain: "Lemon Skunk", tag: "lemon-skunk", type: "Sativa"}, 
    { productType: "flower", strain: "Chitral Sunset", tag: "chitral-sunset", type: "Sativa"}, 
    { productType: "flower", strain: "Infernal Kush", tag: "infernal-kush", type: "Sativa"}, 
    { productType: "flower", strain: "Platinum Dosi", tag: "platinum-dosi", type: "Indica"}, 
    { productType: "flower", strain: "Star Cookie", tag: "star-cookie", type: "Hybrid"}, 
    { productType: "flower", strain: "Wedding Cake", tag: "wedding-cake", type: "Hybrid"}, 
    { productType: "flower", strain: "Peach Crescendo", tag: "peach-crescendo", type: "Hybrid"}, 
    { productType: "flower", strain: "Pillow Factory", tag: "pillow-factory", type: "Hybrid"}, 
    { productType: "flower", strain: "Manic Flavor", tag: "manic-flavor", type: "Hybrid"}, 
    { productType: "flower", strain: "Empire Apples", tag: "empire-apples", type: "Hybrid"}, 
    { productType: "flower", strain: "Koffee Face", tag: "koffee-face", type: "Hybrid"}, 
    { productType: "flower", strain: "Triple Platinum Candy", tag: "triple-platinum-candy", type: "Hybrid"}, 
    { productType: "flower", strain: "Rainbow Chip", tag: "rainbow-chip", type: "Hybrid"}, 
    { productType: "flower", strain: "Citrus Cookie", tag: "citrus-cookie", type: "Hybrid"}, 
    { productType: "flower", strain: "Lemon Royal", tag: "lemon-royal", type: "Hybrid"}, 
    { productType: "flower", strain: "Banana Hammock", tag: "banana-hammock", type: "Hybrid"}, 
    { productType: "flower", strain: "Headband", tag: "headband", type: "Hybrid"}, 
    { productType: "flower", strain: "Cherry Pie", tag: "cherry-pie", type: "Hybrid"}, 
    { productType: "flower", strain: "Weekend in Marseille", tag: "weekend-in-marseille", type: "Indica"}, 
    { productType: "flower", strain: "Wonka Bars", tag: "wonka-bars", type: "Indica"}, 
    { productType: "flower", strain: "Bubble Gum", tag: "bubble-gum", type: "Indica"}, 
    { productType: "flower", strain: "Biohazard", tag: "biohazard", type: "Indica"}, 
    { productType: "flower", strain: "Golden Goat", tag: "golden-goat", type: "Sativa"}, 
    { productType: "flower", strain: "Harborcoat", tag: "Harborcoat", type: "Sativa"}, 
    { productType: "flower", strain: "Lilac Diesel", tag: "lilac-diesel", type: "Sativa"}, 
    { productType: "flower", strain: "Smoked Catfish", tag: "smoked-catfish", type: "Sativa"}, 
    { productType: "flower", strain: "Gasolina", tag: "gasolina", type: "Sativa"}, 
    { productType: "flower", strain: "Jackerella 99", tag: "jackerella-99", type: "Sativa"}, 
    { productType: "flower", strain: "Pangea", tag: "pangea", type: "Hybrid"}, 
    { productType: "flower", strain: "Sunkissed", tag: "sunkissed", type: "Hybrid"}, 
    { productType: "flower", strain: "Mayor Morley", tag: "mayor-morley", type: "Hybrid"}, 
    { productType: "flower", strain: "Hell Bells", tag: "hell-bells", type: "Indica"}, 
    { productType: "flower", strain: "Ohio 47", tag: "ohio-47", type: "Sativa"}, 
    { productType: "flower", strain: "Velvet Pillow", tag: "velvet-pillow", type: "Sativa"}, 
    { productType: "flower", strain: "Tropical Thaighani", tag: "tropical-thaighani", type: "Hybrid"}, 
    { productType: "flower", strain: "10th Planet", tag: "10th-planet", type: "Hybrid"}, 
    { productType: "flower", strain: "Meigs County Cherry Do-si-dos", tag: "meigs-county-cherry-do-si-dos", type: "Hybrid"}, 
    { productType: "flower", strain: "Marionberry Kush", tag: "marionberry-kush", type: "Indica"}, 
    { productType: "flower", strain: "Qleaner", tag: "qleaner", type: "Indica"}, 
    { productType: "flower", strain: "Ghost Ship", tag: "ghost-ship", type: "Indica"}, 
    { productType: "flower", strain: "Forbidden Zkittlez", tag: "forbidden-zkittlez", type: "Hybrid"}, 
    { productType: "flower", strain: "Zkittlez", tag: "zkittlez", type: "Hybrid"}, 
    { productType: "flower", strain: "White Fighter", tag: "white-fighter", type: "Hybrid"}, 
    { productType: "flower", strain: "Silver Lemon Haze(SLH)", tag: "silver-lemon-haze", type: "Sativa"}, 
    { productType: "flower", strain: "Forbidden Fruit", tag: "forbidden-fruit", type: "Hybrid"}, 
    { productType: "flower", strain: "Harle-Tsu", tag: "harle-tsu", type: "Hybrid CBD"}, 
    { productType: "flower", strain: "Blueberry", tag: "blueberry", type: "Indica"}, 
    { productType: "flower", strain: "Blue Cheese", tag: "blue-cheese", type: "Indica"}, 
    { productType: "flower", strain: "Blueberry Cheesecake", tag: "blueberry-cheesecake", type: "Indica"}, 
    { productType: "flower", strain: "Bubba Kush", tag: "bubba-kush", type: "Indica"}, 
    { productType: "flower", strain: "Member OG", tag: "member-og", type: "Indica"}, 
    { productType: "flower", strain: "The Sweeties", tag: "the-sweeties", type: "Indica"}, 
    { productType: "flower", strain: "Snowball S1", tag: "snowball-s1", type: "Hybrid"}, 
    { productType: "flower", strain: "Sticky Buns", tag: "sticky-buns", type: "Hybrid"}, 
    { productType: "flower", strain: "Blue Domina", tag: "blue-domina", type: "Indica"}, 
    { productType: "flower", strain: "NYC Chem", tag: "nyc-chem", type: "Sativa"}, 
    { productType: "flower", strain: "Juggernaut", tag: "juggernaut", type: "Hybrid"}, 
    { productType: "flower", strain: "Kings Mustache", tag: "kings-mustache", type: "Indica"}, 
    { productType: "flower", strain: "Poochie Love", tag: "poochie-love", type: "Hybrid"}, 
    { productType: "flower", strain: "Ultra Sour Chem OG", tag: "ultra-sour-chem-og", type: "Sativa"}, 
    { productType: "flower", strain: "Connie", tag: "connie", type: "Indica"}, 
    { productType: "flower", strain: "Larry OG", tag: "larry-og", type: "Hybrid"}, 
    { productType: "flower", strain: "MAC", tag: "mac", type: "Hybrid"}, 
    { productType: "flower", strain: "Royal Medic", tag: "royal-medic", type: "Hybrid"}, 
    { productType: "flower", strain: "ZSour DBX", tag: "zsour-dbx", type: "Hybrid"}, 
    { productType: "flower", strain: "Tidal Wave", tag: "tidal-wave", type: "Hybrid"}, 
    { productType: "flower", strain: "Dream Catcher", tag: "dream-catcher", type: "Indica"}, 
    { productType: "flower", strain: "Tahiti Lime", tag: "tahiti-lime", type: "Hybrid"}, 
    { productType: "flower", strain: "The Potion", tag: "the-potion", type: "Sativa"}, 
    { productType: "flower", strain: "Catfish", tag: "catfish", type: "Sativa"}, 
    { productType: "flower", strain: "Guice", tag: "guice", type: "Hybrid"}, 
    { productType: "flower", strain: "It's It", tag: "its-it", type: "Indica"}, 
    { productType: "flower", strain: "Tally Mon", tag: "tally-mon", type: "Indica"}, 
    { productType: "flower", strain: "Nepali Queen", tag: "nepali-queen", type: "Sativa"}, 
    { productType: "flower", strain: "Tropicana Cookies", tag: "tropicana-cookies", type: "Hybrid"}, 
    { productType: "flower", strain: "GSC", tag: "gsc", type: "Hybrid"}, 
    { productType: "flower", strain: "2 Fences", tag: "2-fences", type: "Indica"}, 
    { productType: "flower", strain: "Banner", tag: "banner", type: "Sativa"}, 
    { productType: "flower", strain: "White Papaya", tag: "white-papaya", type: "Hybrid"},
    { productType: "flower", strain: "Irie Dough", tag: "irie-dough", type: "Hybrid"},
    { productType: "flower", strain: "Wright State of Mind", tag: "wright-state-of-mind", type: "Indica"},
    { productType: "flower", strain: "Hiker's Blend", tag: "hiker-s-blend", type: "Sativa"},
    { productType: "flower", strain: "Buck Creek Diesel", tag: "buck-creek-diesel", type: "Hybrid"},
    { productType: "flower", strain: "Grape Ape", tag: "grape-ape", type: "Indica"},
    { productType: "flower", strain: "Mad River Kush", tag: "mad-river-kush", type: "Hybrid"},
    { productType: "flower", strain: "Wright Patt Platte", tag: "wright-patt-platte", type: "Hybrid"},
    { productType: "flower", strain: "Platte River Kush", tag: "platte-river-kush", type: "Hybrid"},
    { productType: "flower", strain: "Strawberry Jam", tag: "strawberry-jam", type: "Sativa"},
    { productType: "flower", strain: "Dark Moon", tag: "dark-moon", type: "Indica"},
    { productType: "flower", strain: "Green Panther", tag: "green-panther", type: "Sativa"},
    { productType: "flower", strain: "Tangie", tag: "tangie", type: "Sativa"},
    { productType: "flower", strain: "Meigs County Sauce", tag: "meigs-county-sauce", type: "Sativa"},
    { productType: "flower", strain: "Banana Pudding Tang", tag: "banana-pudding-tang", type: "Hybrid"},
    { productType: "flower", strain: "Mango Punch", tag: "mango-punch", type: "Hybrid"},
    { productType: "flower", strain: "Mandarin Cookies", tag: "mandarin-cookies", type: "Hybrid"},
    { productType: "flower", strain: "Home City Kush", tag: "home-city-kush", type: "Hybrid"},
]