import React from "react";
import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home/Home";
import NotFound from "./containers/NotFound/NotFound";
import Login from "./containers/Login/Login";
import Signup from "./containers/Signup/Signup";

import NewPurchase from "./containers/Purchase/NewPurchase";
import Purchases from "./containers/Purchase/Purchases";

import Cultivators from "./containers/Patient/Cultivators/Cultivators";
import Dispensaries from "./containers/Patient/Dispensaries/Dispensaries";
import MmjDoctors from "./containers/Patient/MmjDoctors/MmjDoctors";
import OhioMmjResources from "./containers/Patient/OhioMmjResources/OhioMmjResources";

import Flower from "./containers/Products/Flower/Flower";
import Topicals from "./containers/Products/Topicals/Topicals";
import Edibles from "./containers/Products/Edibles/Edibles";
import Concentrates from "./containers/Products/Concentrates/Concentrates";

import AboutUs from "./containers/Company/AboutUs/AboutUs";
import ContactUs from "./containers/Company/ContactUs/ContactUs";
import SiteMap from "./containers/Company/SiteMap/SiteMap";

import Over21 from "./containers/Over21/Over21";
import Law from "./containers/Laws/Law";
import FortyFiveDays from "./containers/FortyFiveDays/FortyFiveDays";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <UnauthenticatedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path="/sign-up" exact component={Signup} appProps={appProps} />
      
      <UnauthenticatedRoute path="/patient/cultivators" exact component={Cultivators} appProps={appProps} />
      <UnauthenticatedRoute path="/patient/dispensaries" exact component={Dispensaries} appProps={appProps} />
      <UnauthenticatedRoute path="/patient/mmj-doctors" exact component={MmjDoctors} appProps={appProps} />
      <UnauthenticatedRoute path="/patient/ohio-mmj-resources" exact component={OhioMmjResources} appProps={appProps} />
      
      <UnauthenticatedRoute path="/products/flower" exact component={Flower} appProps={appProps} />
      <UnauthenticatedRoute path="/products/edibles" exact component={Edibles} appProps={appProps} />
      <UnauthenticatedRoute path="/products/concentrates" exact component={Concentrates} appProps={appProps} />
      <UnauthenticatedRoute path="/products/topicals" exact component={Topicals} appProps={appProps} />

      <UnauthenticatedRoute path="/company/about-us" exact component={AboutUs} appProps={appProps} />
      <UnauthenticatedRoute path="/company/contact-us" exact component={ContactUs} appProps={appProps} />
      <UnauthenticatedRoute path="/company/site-map" exact component={SiteMap} appProps={appProps} />

      <UnauthenticatedRoute path="/ohio-laws" exact component={Law} appProps={appProps} />
      <UnauthenticatedRoute path="/calculate/45-days" exact component={FortyFiveDays} appProps={appProps} />

      <AuthenticatedRoute path="/purchases/new" exact component={NewPurchase} appProps={appProps} />
      <AuthenticatedRoute path="/purchases/:id" exact component={Purchases} appProps={appProps} />

      <AppliedRoute path="/over-twenty-one" exact component={Over21} appProps={appProps} />
      <Route component={NotFound} />
    </Switch>
  );
}
