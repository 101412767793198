import React, { useState } from "react";
import "./FortyFiveDays.css";
    
export default function FortyFiveDays(props) {
    const newDate =  localStorage.getItem('startDate45') !== null && !isNaN(new Date(localStorage.getItem('startDate45').toString())) ? 
        new Date(localStorage.getItem('startDate45').toString()) :
        new Date();

    const [startDate, setStartDate] = useState(newDate);

    function convertDate(sDate) {
        const newD = sDate.getFullYear().toString() + "-" + 
            (sDate.getMonth() < 11 ? "0" + (sDate.getMonth() + 1).toString() : (sDate.getMonth() + 1).toString()) + "-" +
            (sDate.getDate() < 10 ? "0" + (sDate.getDate()).toString() : (sDate.getDate()).toString());

        return newD;
    }

    function addDays(date_sent, days) { 
        var ndate = new Date(date_sent.getTime() + (1000 * 60 * 60 * 24 * days));
        return ndate; 
    } 

    function getDates(strDate){
        var listOfDates = [];
        var newStartDate = strDate;
        var i = 0;
        var periodSeries = 0;
        var periodOccurence = 1;
        while(i < 8){
            var bDate = newStartDate;
            var tDate = bDate;
            var numOfDays = 45;
            if(newStartDate.getFullYear() % 4 === 0 && ((newStartDate.getMonth() === 0 && newStartDate.getDate() > 15) || newStartDate.getMonth() === 1)){
                numOfDays = 46;
            } 
            tDate = addDays(newStartDate, numOfDays - 1);
            newStartDate = addDays(newStartDate, numOfDays);
            listOfDates.push({ period: periodSeries.toString() + " - " + periodOccurence.toString(), startDate: bDate, endDate: tDate, numOfDays: numOfDays});
            if(periodOccurence === 2){
                periodOccurence = 1;
                periodSeries++;
            } else {
                periodOccurence = 2;
            }
            i++;
        }

        return [].concat(listOfDates.map( (item, idx) => {
            const sDate = item.startDate;
            const eDate = item.endDate;
            return(
                <div key={"row" + idx} className="mui-row">
                    <div className="mui-col-xs-3">
                        {item.period}
                    </div>
                    <div className="mui-col-xs-3">
                        {(sDate.getMonth() + 1) + "/" + sDate.getDate() + "/" + sDate.getFullYear()}
                    </div>
                    <div className="mui-col-xs-3">
                        {(eDate.getMonth() + 1) + "/" + eDate.getDate() + "/" + eDate.getFullYear()}
                    </div>
                    <div className="mui-col-xs-3">
                        {item.numOfDays}
                    </div>
                </div>
            )
        }));
    }

    const updateDate = (e) => {
        const dtArr = e.target.value.split("-");
        const newDt = dtArr[1] + "/" + dtArr[2] + "/" + dtArr[0] + " 08:00:00 GMT-0400";
        localStorage.setItem('startDate45', newDt);
        setStartDate(new Date(newDt));
    }

    return(
        <div className="container forty-five-days">
            <h1>Forty Five Day Calculator</h1>
            <p>
                Determine your renewal dates in a snap.
            </p>
            <div className="mui-row">
                <div className="mui-col-xs-6 mui-col-md-3">
                    <label htmlFor="startDate">
                        Start Date:
                    </label>
                </div>
                <div className="mui-col-xs-6 mui-col-md-9">
                    <input type="date" id="startDate" value={convertDate(startDate)} onChange={updateDate} />
                </div>
            </div>
            <div className="mui-row header-row">
                <div className="mui-col-xs-3">
                    Fill
                </div>
                <div className="mui-col-xs-3">
                    Start Date
                </div>
                <div className="mui-col-xs-3">
                    End Date
                </div>
                <div className="mui-col-xs-3">
                    Units
                </div>
            </div>
            { getDates(startDate) }
        </div>
    )
};