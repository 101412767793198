export default {
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://h0xttcj0gj.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_SCCfnBjgw",
      APP_CLIENT_ID: "3ei56v0vm773vqqe0mmsbc6i9g",
      IDENTITY_POOL_ID: "us-east-1:22fca9df-d079-4152-b0bf-bd5afa00e58b"
    }
  };