export const concentrates = [
    { productType: "concentrates", concentrate_type: "budder", tag: "budder", description: "Budder", img: "/images/content/Concentrates/budder.png" },
    { productType: "concentrates", concentrate_type: "distillate", tag: "distillate", description: "Distillate", img: "/images/content/Concentrates/distillate.png" },
    { productType: "concentrates", concentrate_type: "live-resin", tag: "live-resin", description: "Live Resin", img: "/images/content/Concentrates/live-resin-sugar.png" },
    { productType: "concentrates", concentrate_type: "rosin", tag: "rosin", description: "Rosin", img: "/images/content/Concentrates/rosin.png" },
    { productType: "concentrates", concentrate_type: "sauce", tag: "sauce", description: "Sauce", img: "/images/content/Concentrates/sauce.png" },
    { productType: "concentrates", concentrate_type: "crumble", tag: "crumble", description: "Crumble", img: "/images/content/Concentrates/wax-crumble.png" },
    { productType: "concentrates", concentrate_type: "shatter", tag: "shatter", description: "Shatter", img: "/images/content/Concentrates/shatter.png" },
    { productType: "concentrates", concentrate_type: "kief", tag: "kief", description: "Kief", img: "/images/content/Concentrates/kief.png" },
    { productType: "concentrates", concentrate_type: "syringe", tag: "syringe", description: "Syringe", img: "/images/content/Concentrates/syringe.png" },
    { productType: "concentrates", concentrate_type: "vape-pen", tag: "vape-pen", description: "Vape Pen" , img: "/images/content/Concentrates/distillate.png"},
]