import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../../libs/hooksLib";
import "./Signup.css";

export default function Signup(props) {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: ""
  });
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }
  
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
  
      props.userHasAuthenticated(true);
      props.history.push("/");
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit} className="mui-form">
        <div className="mui-textfield">
            <input autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
            id="confirmationCode"
            placeholder="Please check your email for the code."
            />
            <label htmlFor="confirmationCode">Confirmation Code</label>
        </div>

        <button
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
          className="mui-btn mui-btn--raised mui-btn--primary"
        >
          Verify
        </button>
      </form>
    );
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit} className="mui-form">
        <div className="mui-textfield">
            <input autoFocus
            type="email"
            id="email"
            value={fields.email}
            onChange={handleFieldChange}
            />
            <label htmlFor="email">Email</label>
        </div>
        <div className="mui-textfield">
            <input type="password"
            id="password"
            value={fields.password}
            onChange={handleFieldChange}
            />
            <label htmlFor="password">Password</label>
        </div>
        <div className="mui-textfield">
            <input type="password"
            id="confirmPassword"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
            />
            <label htmlFor="confirmPassword">Confirm Password</label>
        </div>
        <button
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
          className="mui-btn mui-btn--raised mui-btn--primary"
        >
          Signup
        </button>
      </form>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}