import React from "react";
import "./ContactUs.css";

export default function ContactUs() {
    return (
        <div className="mui-container contact-us">
        <div className="mui-row">
            <div className="mui-col-md-2 mui-col-xs-12 image-cell">
                <img src="../../../images/ohio90logo-header.png" alt="ohio 90 logo" className="body-logo" />
            </div>
            <div className="mui-col-md-10 mui-col-xs-12">
                <h1>Contact Us</h1>
                <p>If you have any questions or concerns you can contact us at <a href="mailto:ohio90mmj@gmail.com">ohio90mmj@gmail.com</a> or at <a href="https://www.twitter.com/ohio90mmj">www.twitter.com/ohio90mmj</a>
                </p>
            </div>
        </div>
    </div>
    )
} 