import React from "react";
import "./MmjDoctors.css";

import { doctors } from "../../../data/doctors";
import ThreeColumns from "../../../components/ThreeColumns";

export default function MmjDoctors() {
    

    const itemFunction = (item) => {
        const mapUrl = "https://www.google.com/maps/search/?api=1&map_action=map&query=" + (!item.hasOwnProperty('address1') ? '' :  
        item.address1.replace('  ', ' ').replace(' ' ,'+') + (item.hasOwnProperty('address2') ?  '+' + item.address2.replace('  ', ' ').replace(' ' ,'+') : '') + '+' +
        item.city.replace('  ', ' ').replace(' ' ,'+') + ',+OH+' + item.postal);
        return (
            <div>
                <h3>{item.office_name}</h3>
                    <ul>
                        {item.hasOwnProperty('address1') && <li>Address: <a  target="_blank" rel="noopener noreferrer" href={mapUrl}>{item.address1} {item.hasOwnProperty('address2') ? item.address2 : ""}</a></li> }
                        {item.hasOwnProperty('address1') && <li>City: {item.city}</li> }
                        {item.hasOwnProperty('website') && <li>Website: <a target="_blank" rel="noopener noreferrer" href={item.website}>Visit</a></li> }
                        {item.hasOwnProperty('phone') && <li>Phone: <a target="_blank" rel="noopener noreferrer" href={"tel:" + item.phone}>Telephone</a></li> }
                        {item.hasOwnProperty('email') && <li>Email: <a target="_blank" rel="noopener noreferrer" href={"mailto:" + item.email}>Email</a></li> }
                    </ul>
            </div>
        );
    }
    const listOfDoctors = doctors.sort((a, b)=> { return a.office_name < b.office_name ? -1 : 1 });

    return (
        <div className="mui-container mmj-doctors">
            <h1>Medical Marijuana Doctors</h1>
            <div className="mui-row">
                <div className="mui-col-xs-12">
                    <h2>Looking for a Doctor</h2>
                </div>
            </div>
            <div className="mui-row">
                
                <div className="mui-col-xs-12 mui-col-md-6">
                    <p>Whether you need to find a doctor or have a question about your condition and if it qualifies, please call any of the doctor offices below.  They would be happy to answer any questions you have about medical marijuana.</p>
                    <p>If you are wondering you need for your visit to your medical marijuana doctor?  I have listed the main items below.</p>  
                    <ul>
                        <li>Make sure you bring your medical reference and documentation from your family physician or recommending doctor. </li>
                        <li>State ID and Military ID(if you are a Vet)</li>
                        <li>Caregiver - they will need to come with you to fill out paper work as well, and make sure they bring their State ID as well.  You may have up to 2 caregivers.  They can pick up your prescription for you if you are unable to get out to do so on your own.</li>
                    </ul>   
                </div>
                <div className="mui-col-xs-12 mui-col-md-6 div-img-container">
                    <img src="/images/content/Patients/mmj-doctor-med.png" alt="Medical Marijuana Doctor and Patient" />
                </div>
            </div>
            <div className="mui-row">
                <div className="mui-col-xs-12">
                    <h3>Qualifying Conditions:</h3>
                </div>
            </div>
            <div className="mui-row">
                <div className="mui-col-xs-12 mui-col-md-6">
                    <ul>
                        <li>AIDS or positive status for HIV</li>
                        <li>Amyotrophic Lateral Sclerosis</li>
                        <li>Alzheimer’s disease</li>
                        <li>Cancer</li>
                        <li>Chronic Traumatic Encephalopathy</li>
                        <li>Crohn’s Disease</li>
                        <li>Epilepsy</li>
                        <li>Fibromyalgia</li>
                        <li>Seizure Disorder</li>
                        <li>Glaucoma</li>
                        <li>Hepatitis C</li>
                    </ul>
                </div>
                <div className="mui-col-xs-12 mui-col-md-6">
                    <ul>
                        <li>Inflammatory Bowel Disease</li>
                        <li>Multiple Sclerosis</li>
                        <li>Pain - Chronic and Severe or Intractable</li>
                        <li>Parkinson’s disease</li>
                        <li>Post-Traumatic Stress Disorder</li>
                        <li>Sickle Cell Anemia</li>
                        <li>Spinal Cord - Disease or Injury</li>
                        <li>Tourette’s Syndrome</li>
                        <li>Traumatic Brain Injury</li>
                        <li>Ulcerative Colitis</li>
                    </ul>
                </div>
            </div>
            <div className="mui-row">
                <div className="mui-col-xs-12">
                    <h3>List of Doctors</h3>
                </div>
            </div>
            {doctors.length > 0 && 
                <div className="mui-container-fluid cultivators">
                    <ThreeColumns listOfItems={listOfDoctors} itemFunction={itemFunction} />
                </div>                
            }
        </div>
    );
}