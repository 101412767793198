import React from "react";

import { cultivators } from "../../../data/cultivators";
import "./Cultivators.css";
import ThreeColums from "../../../components/ThreeColumns";

export default function Cultivators() {
    const itemFunction = (item) => {
        return(
            <div>
                <h3>{item.name}</h3>
                <ul>
                    <li>County: {item.county}</li>
                    <li>City: {item.city}</li>
                    {item.hasOwnProperty('website') &&
                    <li>Website: <a href={item.website}>Visit</a></li>
                    }
                </ul>
            </div>
        );   
    }
    
    const listOfCultivators = cultivators.sort((a, b)=> { return a.name < b.name ? -1 : 1 });
    
    return (
        <div className="mui-container">
            <h2>Cultivators</h2>
            <p>Keep up with those who are growing Ohio Medical Marijuana.</p>
            {listOfCultivators.length > 0 && 
                <div className="mui-container-fluid cultivators">
                    <ThreeColums listOfItems={listOfCultivators} itemFunction={itemFunction} />
                </div>                
            }
        </div>
    );
}