import React from "react";

export default function RadioButtonControl(props) {

    return ([].concat(props.items).map((item, i) =>
        item.value !== undefined &&
            <div className="mui-radio" key={"mui-radio-" + item.id}>
                <label htmlFor={item.id}>
                    <input type="radio" 
                        name={props.radioButtonName}
                        id={item.id}
                        value={item.value}
                        checked={props.value === item.value}
                        onChange={e => props.setFunction(e.target.value)}
                    />
                    {item.name}
                </label>
            </div>
        )
    );
}