import React from "react";
import "./Dispensaries.css";

import { dispensaries } from "../../../data/dispensaries";
import ThreeColumns from "../../../components/ThreeColumns";

export default function Dispensaries() {
    const itemFunction = (item) => {
        const mapUrl = "https://www.google.com/maps/search/?api=1&map_action=map&query=" + item.location.replace('  ', ' ').replace(' ' ,'+');

        return (
            <div>
                <h2>{item.hasOwnProperty('brandname') && item.brandname.length > 0 ? item.brandname : item.name }</h2>
                <ul>
                    <li>Address: <a target="_blank" rel="noopener noreferrer" href={mapUrl}>{item.location}</a></li>
                    <li>County: {item.county}</li>
                    {item.hasOwnProperty('website') && item.website.length > 1 &&
                        <li>Website: <a  target="_blank" rel="noopener noreferrer" href={item.website}>visit</a></li>
                    }
                    {item.hasOwnProperty('email') && item.email.length > 1 &&
                        <li>Email: <a  target="_blank" rel="noopener noreferrer" href={"mailto:" +item.email}>{item.email}</a></li>
                    }
                    {item.hasOwnProperty('phone') && item.phone.length > 1 &&
                        <li>Phone: <a  target="_blank" rel="noopener noreferrer" href={"tel:" + item.phone}>{item.phone}</a></li>
                    }
                </ul>
            </div>
        );
    }
    const listOfDispensaries = dispensaries.sort((a, b)=> { return a.brandname < b.brandname ? -1 : 1 });

    return (
        <div className="mui-container">
            <h2>Dispensaries</h2>
            <ThreeColumns listOfItems={listOfDispensaries} itemFunction={itemFunction} />
        </div>
    )

}