import React from "react";
import "./Topicals.css";

import { topicals } from "../../../data/topicals";
import ThreeColumns from "../../../components/ThreeColumns";

export default function Topicals() {
    const itemFunction = (item) => {
        return(
            <div>
                <h3>{item.description}</h3>
                <div className="div-image-frame">
                    <img src={item.image} alt={item.description} />
                </div>
            </div>
        );
    }
    
    const listOfTopicals = topicals.sort((a, b)=> { return a.description < b.description ? -1 : 1 });
    
    return (
        <div className="mui-container topicals">
            <h2>Topicals</h2>
            <p>Keep up with those who are growing Ohio Medical Marijuana.</p>
            {topicals.length > 0 && 
                <div className="mui-container-fluid cultivators">
                    <ThreeColumns listOfItems={listOfTopicals} itemFunction={itemFunction} />
                </div>                
            }
        </div>
    )
}