export const cultivators = [
    { name: "Terradiol Ohio LLC", tag: "terradiol-ohio-llc", county: "Stark", city: "Canton" },
    { name: "Standard Wellness Company LLC", tag: "standard-wellness-company-llc", county: "Sandusky", city: "Gibsonburg", website: "https://www.standardwellness.com/" },
    { name: "Riviera Creek Holdings LLC", tag: "riviera-creek-holdings", county: "Mahoning", city: "Youngstown", website: "https://rivieracreek.com/" },
    { name: "Pure Ohio Wellness LLC", tag: "pure-ohio-wellness-llc", county: "Clark", city: "Springfield", website: "http://pureohiowellness.com" },
    { name: "PharmaCann Ohio LLC", tag: "pharmacann-ohio-llc", county: "Licking", city: "Village of Buckeye Lake", website: "https://www.pharmacann.com/" },
    { name: "Parma Wellness Center LLC", tag: "pharma-wellness-center-llc", county: "uyahoga", city: "Parma" },
    { name: "OPC Cultivation LLC", tag: "opc-cultivation-llc", county: "Erie", city: "Huron" },
    { name: "Ohio Grown Therapies LLC", tag: "ohio-grown-therapies", county: "Licking", city: "Johnstown", website: "https://www.ohiogrowntherapies.com/" },
    { name: "Harvest Grows LLC", tag: "harvest-grows-llc", county: "Lawrence", city: "Hamilton Township", website: "https://www.harvesthoc.com/" },
    { name: "Grow Ohio Pharmaceuticals LLC", tag: "grow-ohio-pharmaceuticals" , county: "Muskingum", city: "Newton Township", website: "http://growohio.com/"},
    { name: "Greenleaf Gardens", tag: "greenleaf-gardens", county: "Geauga", city: "Middlefield" },
    { name: "Farmacetuical RX LLC", tag: "farmcetuical-rx-llc", county: "Columbiana", city: "East Liverpool", website: "https://farmaceuticalrx.com/" },
    { name: "Cresco Labs Ohio LLC", tag: "cresco-labs-ohio-llc", county: "Greene", city: "Yellow Springs", website: "https://www.crescolabs.com/brands/" },
    { name: "Columbia Care OH LLC", tag: "columbia-care-oh-llc", county: "Brown", city: "Mt. Orab", website: "https://col-care.com/ohio/" },
    { name: "Certified Cultivators", tag: "certified-cultivators", county: "Montgomery", city: "Dayton", website: "https://certifiedcultivators.com/" },
    { name: "Buckeye Relief LLC", tag: "buckeye-relief-llc", county: "Lake", city: "Eastlake", website: "http://www.buckeyerelief.com/" },
    { name: "AT-CPC of Ohio LLC", tag: "at-cpc-of-ohio-llc" , county: "Summit", city: "Akron"},
    { name: "Pure OH LLC", tag: "pure-oh-llc", county: "Columbiana", city: "East Palestine" },
    { name: "Paragon Development Group LLC", tag: "paragon-development-group-llc", county: "Montgomery", city: "Huber Heights" },
    { name: "Ohio Clean Leaf LLC", tag: "ohio-clean-leaf-llc", county: "Montgomery", city: "Dayton" },
    { name: "OhiGrow LLC", tag: "ohigrow-llc", county: "Lucas", city: "Toledo", website: "https://www.facebook.com/pages/category/Media/OhiGrow-143261153032314/" },
    { name: "Mother Grows Best LLC", tag: "mother-grows-best-llc", county: "Stark", city: "Canton" },
    { name: "Hemma LLC", tag: "hemma-llc", county: "Butler", city: "Monroe" },
    { name: "Galenas LLC", tag: "galenas-llc", county: "Summit", city: "Akron", website: "https://galenas.com/" },
    { name: "FN Group Holdings LLC", tag: "fn-group-holdings-llc", county: "Portage", city: "Ravenna" },
    { name: "Fire Rock Ltd", tag: "fire-rock-ltd", county: "Summit", city: "Arkon" },
    { name: "Farkas Farms LLC", tag: "farkas-farm-llc" , county: "Lorain", city: "Grafton"},
    { name: "Ascension BioMedical LLC", tag: "ascension-biomedical-llc", county: "Lorain", city: "Oberlin" },
    { name: "Ancient Roots LLC", tag: "ancient-root-llc", county: "Clinton", city: "Wilmington", website: "https://ancientrootsohio.com/" },
    { name: "Agri-Med Ohio LLC", tag: "agri-med-ohio-llc", county: "Meigs", city: "Langsville", website: "https://meigscountygrown.com/" }
]