import React from "react";
import "./Over21.css";

export default function Over21(props) {
    var localIs21 = localStorage.getItem('is21') === null ? false : 
        localStorage.getItem('is21').toString() === "true" ? true : false;
    var localAsk21 = localStorage.getItem('ask21') === null ? false : 
        localStorage.getItem('ask21').toString() === "true" ? true : false;

    console.log(localIs21);
    console.log(localAsk21);


    if(props.ask21Question || localAsk21){
        if(props.is21){
            props.history.push("/");
        } else {
            if(localIs21) {
                props.setIs21(true);
                props.history.push("/");
            }
            props.history.push("/not-found");
        }
    }

    var yesClick = () => {
        props.setIs21(true);
        localStorage.setItem('is21', true);
        localStorage.setItem('ask21', true);
        props.history.push("/");
    };

    var noClick = () => {
        props.setIs21(false);
        props.setAsk21Question(true);
        localStorage.setItem('is21', false);
        localStorage.setItem('ask21', true);

        props.history.push("/not-found");
    };

    return (
        <div className="mui-container over-21">
            <div className="mui-row">
                <div className="mui-col-xs-12">
                    <h1>Ohio90.com</h1>
                    <h2>Medical Marijuana Patient Resource</h2>
                    <div className="div-image-frame">
                        <img src="/images/ohio90logo-header.png" alt="Ohio90.com" />
                    </div>
                    <p>Are you over 21?</p>
                </div>
            </div>
            <div className="mui-row">
                <div className="mui-col-xs-6">
                    <button className="mui-btn mui-btn--raised mui-btn--primary" onClick={yesClick}>Yes</button>
                </div>
                <div className="mui-col-xs-6">
                    <button className="mui-btn mui-btn--raised mui-btn--danger" onClick={noClick}>No</button>
                </div>
            </div>
        </div>
    );
}