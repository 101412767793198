import React from "react";
import "./Law.css";

export default function Law() {
    return (
    <div className="mui-container law">
        <div className="mui-row">
            <div className="mui-col-xs-12 div-image-container">
                <img src="/images/content/Law/law_header.png" alt="Ohio Law" />
            </div>
        </div>
        <div className="mui-row">
            <div className="mui-col-xs-12 mui-col-md-6">
                <h2>3796:1 Medical Marijuana</h2>
                <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A1-1">Chapter 3796:1-1 Definitions</a></li>
                </ul>
            </div>
            <div className="mui-col-xs-12 mui-col-md-6">
                <h2>3796:2 Cultivators</h2>
                <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A2-1">Chapter 3796:2-1 Licensing; Certificates of Operation</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A2-2">Chapter 3796:2-2 Cultivator Operations</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A2-3">Chapter 3796:2-3 Cultivator Inspections</a></li>
                </ul>
            </div>
        </div>
        <div className="mui-row">
            <div className="mui-col-xs-12 mui-col-md-6">
                <h2>3796:3 Processors</h2>
                <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A3-1">Chapter 3796:3-1 Generally</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A3-2">Chapter 3796:3-2 Operations</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A3-3">Chapter 3796:3-3 Enforcement</a></li>
                </ul>
            </div>
            <div className="mui-col-xs-12 mui-col-md-6">
                <h2>3796:4 Testing Laboratories</h2>
                <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A4-1">Chapter 3796:4-1 Generally</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A4-2">Chapter 3796:4-2 Operations</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A4-3">Chapter 3796:4-3 Enforcement</a></li>
                </ul>
            </div>
        </div>
        <div className="mui-row">
            <div className="mui-col-xs-12 mui-col-md-6">
                <h2>3796:5 Administration and Enforcement</h2>
                <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A5-1">Chapter 3796:5-1 Fees</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A5-2">Chapter 3796:5-2 Employees</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A5-3">Chapter 3796:5-3 Transportation</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A5-4">Chapter 3796:5-4 Loss or Theft</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A5-5">Chapter 3796:5-5 Distance from Public Spaces</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A5-6">Chapter 3796:5-6 Enforcement</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A5-7">Chapter 3796:5-7 Advertising</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A5-8">Chapter 3796:5-8 Product Registration</a></li>
                </ul>
            </div>
            <div className="mui-col-xs-12 mui-col-md-6">
                <h2>3796:6 Dispensaries</h2>
                <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A6-1">Chapter 3796:6-1 Definitions</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A6-2">Chapter 3796:6-2 Generally</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A6-3">Chapter 3796:6-3 Operations</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A6-4">Chapter 3796:6-4 Enforcement</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A6-5">Chapter 3796:6-5 Fees</a></li>
                </ul>
            </div>
        </div>
        <div className="mui-row">
            <div className="mui-col-xs-12 mui-col-md-6">
                <h2>3796:7 Patients</h2>
                <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A7-1">Chapter 3796:7-1 Definitions</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A7-2">Chapter 3796:7-2 Registration of Patients and Caregivers</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A7-3">Chapter 3796:7-3 Fees</a></li>
                </ul>
            </div>
            <div className="mui-col-xs-12 mui-col-md-6">
                <h2>3796:8 Administration</h2>
                <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A8-1">Chapter 3796:8-1 Definitions</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A8-2">Chapter 3796:8-2 Form of Doses</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://codes.ohio.gov/oac/3796%3A8-3">Chapter 3796:8-3 Fees</a></li>
                </ul>
            </div>
        </div>
    </div>
    )
} 