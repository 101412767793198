import React, { useState, useEffect } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import './App.css';
import Routes from "./Routes";

import Menu from "./containers/Menu/Menu";

function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated ] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [is21, setIs21] = useState(false);
  const [ask21Question, setAsk21Question] = useState(false);
  let history = useHistory();

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    props.history.push("/login");
  }

  function displayMenu(){
    const showMenuNew = !showMenu;
    setShowMenu(showMenuNew);
  }


  return (!isAuthenticating &&
    <div className="mui-container">
      {showMenu &&
      <div className="side-menu" style={{ minHeight: document.getElementById("main_view_pt").scrollHeight }}>
        <Menu onTitleClick={displayMenu} onItemSelect={displayMenu} />
      </div>
      }
      <div id="main_view_pt" className={showMenu ? "side-menu-view" : ""} >
        <div className="mui-appbar">
          <div className="mui-container-fluid">
            <div className="mui-row">
              <div className="mui-col-xs-6 first-header-div">
                <Link className="menu-main-link" to="#" onClick={displayMenu}>{!showMenu ? <span>&#x2630;</span> : <span>&#x2637;</span>}</Link>
                <Link className="menu-title-link" to="/"><img className="header-image" src="/images/ohio90logo-header.png" alt="Ohio 90 logo" /></Link>
              </div>
              <div className="mui-col-xs-6">
              {1 === 0 &&
                <ul className="header-menu">
                  {isAuthenticated 
                    ? 
                      <>
                        <li><Link to="#" onClick={handleLogout}>Logout</Link></li>
                      </>
                    : <>
                        <li><Link to="/sign-up">Sign Up</Link></li>
                        <li><Link to="/login">Login</Link></li>
                      </>
                  }
                </ul>
              }
              </div>
            </div>
          </div>
        </div>
        <div className="mui-panel">
          <Routes appProps={{ isAuthenticated, userHasAuthenticated, is21, setIs21, history, ask21Question, setAsk21Question }} />
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
