export const sizes = [
    { value: "2.83", text: "2.83g(1/10)" },
    { value: "5.66", text: "5.66g(2/10)" },
    { value: "8.49", text: "8.49g(3/10)" },
    { value: "14.15", text: "1/2 oz" },
    { value: "28.3", text: "1oz)" },
    { value: "56.60", text: "2oz" },
    { value: "1", text: "1g" },
    { value: "2", text: "2g" },
    { value: "3", text: "3g" },
    { value: "3.5375", text: "1/8oz"},
    { value: "7.075", text: "1/4oz"},
]