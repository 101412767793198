import React, { useState } from "react";

import "./Home.css";
import { Link } from "react-router-dom";
export default function Home(props) {
  const [listOfItems, setListOfItems] = useState([]);
  const [listOfNews, setListOfNews] = useState([]);
  
  function getData(){
    fetch('data/products/deals.json', { mode: 'cors'})
    .then( (response) => {
      return response.json()
    }).then((data) => {
      //console.log("here");
      setListOfItems(data.deals)
    });
  }

  function getNewsData(){
    fetch('data/news/news.json', { mode: 'cors'})
    .then( (response) => {
      return response.json()
    }).then((data) => {
      setListOfNews(data.news)
    });
  }

  function renderLander() {
    return (
      <div className="lander mui-container">
        <div className="mui-row">
          <div className="mui-col-md-2 mui-col-xs-12">
            <img src="/images/ohio90logo-header.png" alt="ohio 90 logo" className="body-logo" />
          </div>
          <div className="mui-col-md-10 mui-col-xs-12">
            <h1>ohio90.com - <span className="motto">Midwest Medical Marijuana Resource</span></h1>
            <p>Know how much you have to use so you don't run out, we have the direct link to the resources that you need to track your days.  We are also trying to provide an overall guide and reference to your overall Medicial Marijuana Medication experience.  
              If you have any comments or suggestions please contact us at <a href="mailto:ohio90mmj@gmail.com">ohio90mmj@gmail.com</a></p>
          </div>
        </div>
        <div className="mui-row two-col-front">
          <div className="mui-col-md-6 mui-col-xs-12">
            <h2>Latest Sightings</h2>
            <div className="img-frame"><img src="/images/content/Home/latest-sightings-dispensaries.png" alt="Artist rendering of medical dispensary" /></div>
            <p>We are always keeping an eye out for new product as it hits the market.  We will keep you updated with the latest new product around Ohio.</p>
            <ul>
            {listOfItems.length && listOfItems.map((item, key) => <li key={"deals" + key}>{item.announcement + " at " + item.dispensary}</li>)}
            </ul>
          </div>
          <div className="mui-col-md-6 mui-col-xs-12">
          <h2>Latest News</h2>
          <div className="img-frame"><img src="/images/content/Home/latest-news.png" alt="Latest News" /></div>
          <p>Get the latest news and updates on the medical marijuana scene in Ohio here.  Openings, closings, and state decisions.</p>
            <ul>
              {listOfNews && listOfNews.map((item, key) => <li key={"news" + key}><strong>{item.title}</strong> {item.brief && item.brief + " "}<a target="_blank" rel="noopener noreferrer"  href={item.website_url}>{item.url_name}</a></li>)}
            </ul>
          </div>
        </div>
        <div className="mui-row three-col-front">
          <div className="mui-col-md-4 mui-col-xs-12">
            <h2>How Many Days Do I Have Left?</h2>
            <div className="img-frame"><img src="/images/content/Home/mmj_example_card.png" alt="MMJ Card Example" /></div>
            <p>You can find how many units you have left in the current 45-day period by logging into the state patient site at <a target="_blank" rel="noopener noreferrer" href="https://www.ohiomedicalmarijuanaregistry.com">ohiomedicalmarijuanaregistry.com</a></p>
            <p>Calculate starting and ending dates for the year per 45 days with our <Link to="/calculate/45-days">45 day Calculator</Link>.</p>
          </div>
          <div className="mui-col-md-4 mui-col-xs-12">
            <h2>State MMJ Site</h2>
            <div className="img-frame"><img src="/images/content/Home/StateWebsite.png" alt="Ohio State Medical Marijuana Website" /></div>
            <p>If you are looking for information about the medical marijuana program in Ohio you can visit their website at <a target="_blank" rel="noopener noreferrer" title="State of Ohio Medical Marijuana Site" href="https://www.medicalmarijuana.ohio.gov/default">State of Ohio site</a>.  You can information on becoming a patient, caregiver, dispensaries, cultivation, and other information.</p>
          </div>
          <div className="mui-col-md-4 mui-col-xs-12">
            <h2>Ohio Laws</h2>
            <div className="img-frame"><img src="/images/content/Home/ohio-marijuana-laws.jpg" alt="Ohio Marijuana Laws" /></div>
            <p>Laws for both patients, cultivators, dispensaries, and what you need to know.  We have located the laws and will do our best to keep you updated on any changes that come up that could impact your medicinal needs.</p>
            <p>Here is a complete list of the laws:<br /> <Link to="/ohio-laws">Current Ohio Medical Marijuana Laws</Link></p>
          </div>
        </div>
        <div className="mui-row two-col-front">
          <div className="mui-col-md-6 mui-col-xs-12">
            <h2>Dispensaries</h2>
            <div className="img-frame"><img src="/images/content/Home/dispensaries-ohio.png" alt="Ohio Medical Marijuana Dispensaries" /></div>
            <p>Need to find a dispensary?  Wonder what the first experience will be like?  Let us help you with that to make it easy and get you to your desired location.  
              We have a comprehensive list of all the dispensaries in Ohio and what they are carrying in their store. Check out our <Link to="/patient/dispensaries">list of dispensaries</Link>.</p>
          </div>
          <div className="mui-col-md-6 mui-col-xs-12">
            <h2>Doctors of Ohio</h2>
            <div className="img-frame"><img src="/images/content/Home/doctor-visit.png" alt="Visiting the doctor" /></div>
            <p>Need to find a doctor to get a prescription from in Ohio, we have a list of all the offices in the state.  Their address, phone number, and website.  We even have a list of telemedicine sites as well. <Link to="/patient/mmj-doctors">List of Doctors</Link></p>
          </div>
        </div>
        <div className="mui-row">
          <div className="mui-col-xs-12 one-col-front">
            <h2>Strains</h2>
            <Link to="/products/flower"><img src="/images/content/Home/strains-all.png" alt="Sativa, Indica, Hybrid" /></Link>
            <p>We have a list of most the strains being sold in the medical dispensaries in Ohio and we are always keeping up to date.  
              We also provide more detailed information on each strain such as parents, cultivators, terpenes for each plant.
            </p>
            <p><Link to="/products/flower">List of Strains</Link></p>
          </div>
        </div>
        <div className="mui-row three-col-front bottom">
          <div className="mui-col-md-4 mui-col-xs-12">
            <h2>Products of Ohio</h2>
            <div className="img-frame"><img src="/images/content/Home/products-of-ohio.png" alt="Medical Marijuana Products of Ohio - Editorial credit: Kimberly Boyles / Shutterstock.com" title="Medical Marijuana Products of Ohio" /></div>
            <p>Ohio has a wide range of products for your medical use. We have a list of all of them and how to use them to medicate.</p>
            <ul>
              <li><Link to="/products/flower">Flower</Link></li>
              <li><Link to="/products/concentrates">Concentrates</Link></li>
              <li><Link to="/products/edibles">Edibles</Link></li>
              <li><Link to="/products/topicals">Topicals</Link></li>
            </ul>
          </div>
          <div className="mui-col-md-4 mui-col-xs-12">
            <h2>Accessories</h2>
            <div className="img-frame"><img src="/images/content/Home/accessories-vape.png" alt="Ohio Marijuana Laws" /></div>
            <p>After you get your flower or concentrate you will need a device to use to vape from and we have a recommended list of winners and losers and where you can find them on the net and around the state of Ohio.</p>
          </div>
          <div className="mui-col-md-4 mui-col-xs-12">
            <h2>Help for Addiction</h2>
            <div className="img-frame"><img src="/images/content/Home/addiction-image.png" alt="Addiction Help" /></div>
            <p>Everyone is effected differently at a biochemical level by different things we ingest, including THC.  Some people cannot and should not use it, for the most part this is rare.  But if you are having trouble and are staying home, not getting out and all you think about is the addication. Please get help. You can call Ohio Addiction Resource at <a href="tel:800-481-8457">800-481-8457</a> or at their website <a href="https://www.ohioarc.com/">https://www.ohioarc.com/</a>.</p>
          </div>
        </div>
      </div>
    );
  }
  if(listOfNews.length < 1) {
    getNewsData()
  }
  if(listOfItems.length < 1) {
    getData()
  }
  return (
    <div className="Home">
      {renderLander()}
    </div>
  );
}
