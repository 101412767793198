import React from "react";

import "./Flower.css"

import { products } from "../../../data/products";

export default function Flower() {
    var tcol1 = "", tcol2 = "", tcol3 = "";
    
    var indica_flower = products.filter(item => item.type === "Indica").sort((a, b)=> { return a.strain < b.strain ? -1 : 1 });

    console.log(indica_flower);
    var new_indica_flower = indica_flower.map((item, key) => {
        switch(key % 3){
            case 0:
                tcol1 = "";
                tcol2 = "";
                tcol3 = "";
                tcol1 = <div key={"indica-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
            break;
            case 1:
                tcol2 = <div key={"indica-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
            break;
            default:
                tcol3 = <div key={"indica-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
        }

        return (key % 3 === 2 &&
            <div key={"indica-row-" + key} className="mui-row">
                {tcol1}
                {tcol2}
                {tcol3}
            </div>);
    });

    var sativa_flower = products.filter(item => item.type === "Sativa").sort((a, b)=> { return a.strain < b.strain ? -1 : 1 });

    var new_sativa_flower = sativa_flower.map((item, key) => {
        switch(key % 3){
            case 0:
                tcol1 = "";
                tcol2 = "";
                tcol3 = "";
                tcol1 = <div key={"sativa-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
            break;
            case 1:
                tcol2 = <div key={"sativa-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
            break;
            default:
                tcol3 = <div key={"sativa-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
        }

        return (key % 3 === 2 &&
            <div key={"sativa-row-" + key} className="mui-row">
                {tcol1}
                {tcol2}
                {tcol3}
            </div>);
    });

    var hybrid_flower = products.filter(item => item.type === "Hybrid").sort((a, b)=> { return a.strain < b.strain ? -1 : 1 });

    var new_hybrid_flower = hybrid_flower.map((item, key) => {
        switch(key % 3){
            case 0:
                tcol1 = "";
                tcol2 = "";
                tcol3 = "";
                tcol1 = <div key={"hybrid-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
            break;
            case 1:
                tcol2 = <div key={"hybrid-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
            break;
            default:
                tcol3 = <div key={"hybrid-col-name" + key} className="mui-col-md-4 mui-col-xs-12 data-item">
                    <h3>{item.strain}</h3>
                </div>;
        }

        return (key % 3 === 2 &&
            <div key={"hybrid-row-" + key} className="mui-row">
                {tcol1}
                {tcol2}
                {tcol3}
            </div>);
    });

    return (
        <div className="mui-container product-flower">
            <h1>Flower</h1>
            <h2>Indica</h2>
            <img src="/images/content/Flower/indica-pic.png" alt="Indica" className="section-image" />
            <div className="mui-container">
                {new_indica_flower}
            </div>
            <h2>Sativa</h2>
            <img src="/images/content/Flower/sativa-pic.png" alt="Sativa" className="section-image" />
            <div className="mui-container">
                {new_sativa_flower}
            </div>
            <h3>Hybrid</h3>
            <img src="/images/content/Flower/Hybrid-pic.png" alt="Hybrids" className="section-image" />
            <div className="mui-container">
                {new_hybrid_flower}
            </div>
        </div>
    );
}