import React from "react";

export default function RatingButtonControl(props) {

    function displayStars(num){
        const stars = [];
        var i = 0;
        while(i < num){
            stars.push(<span key={"sp" + i}>&#9733;</span>);
            i++;
        }

        return (<div className="rating">{stars}</div>);
    }

    return ([].concat(props.items).map((item, i) =>
        item.value !== undefined &&
            <div className="mui-radio" key={"mui-radio-" + item.id}>
                <label htmlFor={item.id}>
                    <input type="radio" 
                        name={props.radioButtonName}
                        id={item.id}
                        value={item.value}
                        checked={props.value === item.value}
                        onChange={e => props.setFunction(e.target.value)}
                    />
                    {displayStars(item.value)}
                </label>
            </div>
        )
    );
}