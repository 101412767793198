import React from "react";
import "./SiteMap.js"

import { Link } from "react-router-dom";

export default function SiteMap() {
    return (
    <div className="mui-container site-map">
        <h1>Site Map</h1>
        <div className="mui-row">
            <div className="mui-col-md-6 mui-col-xs-12">
                <h2>Main</h2>
                <ul>
                    <li><Link to="/">Home</Link></li>
                </ul>
            </div>
            <div className="mui-col-md-6 mui-col-xs-12">
                <h2>Patient</h2>
                <ul>
                    <li><Link to="/patient/dispensaries">Dispensaries</Link></li>
                    <li><Link to="/patient/cultivators">Cultivators</Link></li>
                    <li><Link to="/patient/mmj-doctors">MMJ Doctors</Link></li>
                    <li><Link to="/patient/ohio-mmj-resources">Ohio MMJ Resources</Link></li>
                </ul>
            </div>
        </div>
        <div className="mui-row">
            <div className="mui-col-md-6 mui-col-xs-12">
                <h2>Products</h2>
                <ul>
                    <li><Link to="/products/flower">Flower</Link></li>
                    <li><Link to="/products/concentrates">Concentrates</Link></li>
                    <li><Link to="/products/edibles">Edibles</Link></li>
                    <li><Link to="/products/topicals">Topicals</Link></li>
                </ul>
            </div>
            <div className="mui-col-md-6 mui-col-xs-12">
                <h2>Company</h2>
                <ul className="side-menu-sub-ul">
                    <li><Link to="/company/about-us">About Us</Link></li>
                    <li><Link to="/company/contact-us">Contact Us</Link></li>
                    <li><Link to="/company/site-map">Site Map</Link></li>
                </ul>
            </div>
        </div>
    </div>
    )
}