import React from "react";
import "./OhioMmjResources.css";

import { ohio_flower_chart } from "../../../data/flower_chart";

export default function OhioMmjResources() {

    const listOfchart = ohio_flower_chart.map((item, key) => {
        return (<div key={"ofc-" + key} className="mui-row">
                    <div key={"ofc-size-" + key} className="mui-col-xs-3">
                        {item.size}
                    </div>
                    <div key={"ofc-grams-" + key} className="mui-col-xs-3">
                        {item.grams}
                    </div>
                    <div key={"ofc-cost1-" + key} className="mui-col-xs-3">
                        {item.tier1_cost}
                    </div>
                    <div key={"ofc-cost2-" + key} className="mui-col-xs-3">
                        {item.tier2_cost}
                    </div>
                </div>);
    });


    return (
        <div className="mui-container">
            <h1>Ohio Medical Marijuana Resources</h1>
            <p>According to Ohio law each patient can purchase 90 units of Medical Marijuana every 90 days.</p>  
            <p>The actual amount varies based off:</p> 
            <ul>
                <li>Type of product</li>
                <li>THC level for everything except concentrates</li>
                <li>Amount purchased for Flower</li>
            </ul>
            <p>The information below explains how many units you are charged per amount of medical marijuana unit.</p>

            <h2>Concentrate Usage</h2>
            <p>For conentrates, 1 item purchase is equal to one day regardless of size or potency.</p>
            
            <h2>Edibles and Topcials</h2>
            <p>For Edibles and Topcials, every 100mg of THC is equal to one day.</p>
            
            <h2>Flower Usage</h2>
            <p>The diagram below shows the number of days you use per amount of Tier 1 or Tier 2 you purchase. (Tier 1 = THC less than 23 percent, Tier2 = THC greater than 22.99 percent)</p>
            <p><strong>Remember if you buy the same brand and strain, regardless of the number of containers you have to buy, your days are charged by the total amount by brand and strain.</strong> 
            So if you go to the dispensary and purchase 10 1/10th of Northern Lights Tier 1 by Ohio Local Cannabis you would get charged for 1 ounce worth of day usage not 10 separate 1/10 of days usage, 
            which will save you day usage for your 45/90 day perscription period.  Be careful, alot of first timers run out buying different strains and different brands in one visit.</p>
            {listOfchart.length > 0 && 
                <div className="mui-container-fluid resources">
                    <div key="1" className="mui-row header-row">
                        <div key="header-tenth" className="mui-col-xs-3">
                            1/10
                        </div>
                        <div key="header-grams" className="mui-col-xs-3">
                            Grams
                        </div>
                        <div key="header-tier-1" className="mui-col-xs-3">
                            Tier 1
                        </div>
                        <div key="header-tier-2" className="mui-col-xs-3">
                            Tier 2
                        </div>
                    </div>
                    {listOfchart}  
                </div>                
            }
        </div>
    )
}