import React from "react";
import "./Concentrates.css"
import { concentrates } from "../../../data/concentrates";
import ThreeColumns from "../../../components/ThreeColumns";

export default function Concentrates() {
    const itemFunction = (item) => {
        return(
            <div>
                <h3>{item.description}</h3>
                <div className="div-img-container">
                    <img src={item.img} alt={item.description} />
                </div>
            </div>
        );
    }
    
    const listOfConcentrates = concentrates.sort((a, b)=> { return a.description < b.description ? -1 : 1 });
    
    return (
        <div className="mui-container concentrates-container">
            <h2>Concentrates</h2>
            <p>Keep up with those who are growing Ohio Medical Marijuana.</p>
            {listOfConcentrates.length > 0 && 
                <div className="mui-container-fluid concentrates">
                    <ThreeColumns listOfItems={listOfConcentrates} itemFunction={itemFunction} />
                </div>                
            }
        </div>
    )
}